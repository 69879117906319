import React, { useState, useEffect, Fragment } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

import { useUserState } from "../../../Contexts/UserContext";
import Basic from "../../../assets/images/basic.png";
import Delux from "../../../assets/images/delux.png";
import Premium from "../../../assets/images/premium.png";

const ChooseFurniturePackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserState();
  const [furPlan, setFurPlan] = useState([]);
  const [loader, setLoader] = useState(false);


  const getCategoriesData = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    setLoader(true);
    try {
      let res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/furniture-category/get-list`,
        { headers: headersFormData }
      );
      setFurPlan(res?.data?.data);
      setLoader(false);
    } catch (err) {
      console.log("Error while getting furniture plan", err?.message);
      setLoader(false);
    }
  };

  const handlingRouteData = () => {
    if (
      !location?.state?.houseId ||
      !location?.state?.cartId ||
      !location?.state?.startDate ||
      !location?.state?.endDate
    ) {
      navigate("/");
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, [user?.token]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    handlingRouteData();
  }, []);

  return (
    <Fragment>
      <div className="banner-section customise-furniture-back">
        <div className="all-property-sec customise-furniture-sec">
          <div className="container-fluid my-container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="search-header">
                  <h2>
                    Choose <span>Furniture </span>package
                  </h2>
                </div>
              </div>
              {loader && (
                
                <div class="eye-loader-frame m-5 p-5">
                <div class="eye-loader-spinner"></div>
                </div>
              )}

              {furPlan?.length > 0 &&
                furPlan?.map((ele) => {
                  return (
                    <div
                      className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                      key={ele?.id}
                    >
                      <div
                        className="property-inner furniture-content"
                        onClick={() =>
                          navigate(`/furniture`, {
                            state: {
                              houseId: location?.state?.houseId,
                              cartId: location?.state?.cartId,
                              startDate: location?.state?.startDate,
                              endDate: location?.state?.endDate,
                              furPlanId: ele?.id,
                              furPlan: ele?.name,
                            },
                          })
                        }
                      >
                        <img
                          src={
                            ele?.name === "Premium Furniture"
                              ? Premium
                              : ele?.name === "Delux Furniture"
                              ? Delux
                              : ele?.name === "Basic Furniture"
                              ? Basic
                              : ""
                          }
                        />
                        <div className="property-content">
                          <div className="property-discription">
                            <h4>{ele?.name}</h4>
                            <a>Choose plans</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChooseFurniturePackage;
