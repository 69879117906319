import { Fragment } from "react";

import AppPromo from "../../home/apppromo/AppPromo";
import Banner from "./banner";
import CleaningOtherPlans from "./cleaning-other-plans/index";
// import CleaningPlan from "./cleaning-plan";

const CleaningUserSideScreen = () => {
  return (
    <Fragment>
     <style>
        {`
          
          .cleaning-new-sec {
            margin: 0 !important;
          }
          .cleaning-new-sec .fur-about-content {
            margin: 0;
          }
          .cleaning-company-sec {
            margin: 0 0 30px;
            text-align:center;
          }
          .cleaning-company-sec .trusted-content {
            margin: 10px 0 0;
          }
          .cleaning-company-sec .trusted-part-title h2 {
            font-size: 24px;
            color: #0474ba;
          }
          .change-company {
            background-color: #0474BA;
            color: #fff !important;
            padding: 7px 15px;
            border-radius: 45px;
            border: none;
            display: inline-block;
            max-width: 250px;
            text-align: center;
            margin: 0 10px 20px;
            margin-left: auto;
          }
        `}
      </style>
     <Banner />
     {/*<CleaningPlan />*/}
     <CleaningOtherPlans />
      <AppPromo />

    </Fragment>
  );
};

export default CleaningUserSideScreen;
