import { Fragment, useEffect, useState, useContext } from "react";

import { ColorPicker, useColor } from "react-color-palette";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { useUserState, UserContext } from "../../../Contexts/UserContext";
import Button from "../../common/LoadingButton";

import UploadImg from "../../../assets/images/upload.png";
import LightSvg from "../../../assets/images/Light.svg";
import CloseCircleSvg from "../../../assets/images/close-circle.svg";
import StorebedImg from "../../../assets/images/storebed.png";

import "react-color-palette/css";

const AddNewFurniture = () => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
const { categoryId } = location.state || {};

  const { furEditData } = useContext(UserContext);

  const [inputData, setInputData] = useState({
    furnitureName: furEditData?.name ? furEditData?.name : "",
    furnitureCat: furEditData?.furniture_category_id
      ? furEditData?.furniture_category_id
      : categoryId,
    furniturePrice: furEditData?.price ? furEditData?.price : "",
    furnitureMaterial: furEditData?.material ? furEditData?.material : "",
    furnitureBrand: furEditData?.brand ? furEditData?.brand : "",
    furnitureDes: furEditData?.description ? furEditData?.description : "",
  });
  const [inputError, setInputError] = useState(false);
  const [urlData, setUrlData] = useState(
    furEditData?.furniture_images ? furEditData?.furniture_images : []
  );
  const [imgName, setImgName] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const MainColors = [
    "white",
    "red",
    "green",
    "yellow",
    "orange" /* add more colors as needed */,
  ];
  let defaultColor = furEditData?.color ? furEditData?.color : "#00FF00";
  const [color, setColor] = useColor(defaultColor);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const headersFormData = {
      "Content-Type": "multipart/form-data",
    };
    try {
      let formData = new FormData();
      formData.append("image", file);
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/image/upload`,
        formData,
        { headers: headersFormData }
      );
      setUrlData([{imageUrl : res?.data?.data?.imageUrl}]);
      setImgName(res?.data?.data?.name);
    } catch (err) {
      return console.log(err);
    }
  };

  const getCategoriesData = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    try {
      let res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/furniture-category/get-list`,
        { headers: headersFormData }
      );
      setCategoryData(res?.data?.data);
    } catch (err) {
      return console.log("get_category", err);
    }
  };

  const handleKeyPhone = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    const {
      furnitureName,
      furnitureCat,
      furniturePrice,
      furnitureDes,
      furnitureBrand,
      furnitureMaterial,
    } = inputData;
    if (
      !furnitureName ||
      !furnitureCat ||
      !furniturePrice ||
      !furnitureBrand ||
      !color ||
      !furnitureMaterial ||
      !urlData?.length > 0
    ) {
      setInputError(true);
    } else {
      setInputError(false);

      const headerJson = {
        "Content-Type": "application/json",
        Authorization: user?.token,
      };
      const data = {
        name: furnitureName,
        price: furniturePrice,
        description: furnitureDes,
        furniture_category_id: furnitureCat,
        furniture_img: [urlData[0]?.imageUrl],
        color: color.hex,
        material: furnitureMaterial,
        brand: furnitureBrand,
      };

      try {
        setButtonLoader(true);
        const res = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/furniture/create`,
          data,
          { headers: headerJson }
        );
        if (res) {
          setButtonLoader(false);
          setInputData({
            furnitureName: "",
            furnitureCat: "",
            furniturePrice: "",
            furnitureDes: "",
            furnitureBrand: "",
            furnitureMaterial: "",
          });
          setColor("hex", "#00FF00");

          setUrlData([]);
          navigate("/user_dash/furniture_company/furniture_store");
        }
      } catch (err) {
        console.log(err);
        setButtonLoader(false);
      }
    }
  };

  const handleEditFurniture = async () => {
    const {
      furnitureName,
      furnitureCat,
      furniturePrice,
      furnitureDes,
      furnitureMaterial,
      furnitureBrand,
    } = inputData;
    if (
      !furnitureName ||
      !furnitureCat ||
      !furniturePrice ||
      !urlData?.length > 0
    ) {
      setInputError(true);
    } else {
      setInputError(false);

      const headerJson = {
        "Content-Type": "application/json",
        Authorization: user?.token,
      };
      const data = {
        name: furnitureName,
        price: furniturePrice,
        description: furnitureDes,
        furniture_category_id: furnitureCat,
        furniture_img: [urlData[0]?.imageUrl],
        color: color.hex,
        material: furnitureMaterial,
        brand: furnitureBrand,
      };

      try {
        setButtonLoader(true);
        const res = await axios.put(
          `${import.meta.env.VITE_APP_BASE_URL}/api/furniture/update/${
            furEditData?.id
          }`,
          data,
          { headers: headerJson }
        );
        if (res) {
          setButtonLoader(false);
          setInputData({
            furnitureName: "",
            furnitureCat: "",
            furniturePrice: "",
            furnitureDes: "",
          });
          setUrlData([]);
          navigate("/user_dash/furniture_company/furniture_store");
        }
      } catch (err) {
        console.log(err);
        setButtonLoader(false);
      }
    }
  };

  useEffect(() => {
    getCategoriesData();
    // eslint-disable-next-line
  }, [user?.token]);

  // color picker function
  function closestColor(pickedColor) {
  // Calculate the closest main color based on Euclidean distance in RGB space
  const closest = MainColors.reduce((prev, curr) => {
    const prevDiff = prev.reduce((acc, val, i) => acc + Math.abs(val - pickedColor[i]), 0);
    const currDiff = curr.reduce((acc, val, i) => acc + Math.abs(val - pickedColor[i]), 0);
    return currDiff < prevDiff ? curr : prev;
  });
  return closest;
}

  const handleColorChange = (newColor) => {
    // Convert newColor to RGB array [r, g, b]
    const rgbArray = newColor.rgb.map((channel) => Math.round(channel * 255));
    // Find the closest main color
    const closestMainColor = closestColor(rgbArray);
    // Convert RGB array back to CSS color string
    const closestColorString = `rgb(${closestMainColor.join(",")})`;
    // Set the color
    setColor(closestColorString);
  };

  return (
    <Fragment>
      <div className="main-box">
        <div className="content-header">
          <h3>{furEditData ? "Edit Furniture" : "Add New Furniture"}</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="add_new_img_upload">
              <div className="house_upload_head">
                Upload your furniture image
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="add_new_house">
                    <div className="Neon Neon-theme-dragdropbox furniture-upload-sec">
                      <input
                        name="files[]"
                        id="filer_input2"
                        multiple="multiple"
                        accept=".jpg, .png, .jpeg"
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                      <div className="Neon-input-dragDrop">
                        <div className="Neon-input-inner">
                          <div className="Neon-input-icon">
                            <img src={UploadImg} />
                          </div>
                          <div className="Neon-input-text">
                            <p>
                              Drop your file here to upload or select from
                              storage
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {inputError && !urlData?.length > 0 && (
                      <div className="upload-error-message">
                        <span>Please upload your file</span>
                      </div>
                    )}

                    {/* <div className="br_btn furniture-brows">
                    <input
                        name="files[]"
                        id="filer_input2"
                        multiple="multiple"
                        accept=".jpg, .png, .jpeg"
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                      <button type="button">Browse</button>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <div className="add_new_progres">
                    <div className="upload_jpg">
                      <div className="doc_img">
                        <img src={LightSvg} alt="Light" />
                      </div>
                      <div className="file_jpg_main">
                        <div className="file_jpg">
                          <h4>File_Name.Jpg</h4>
                          <h4>92%</h4>
                        </div>
                        <div className="progress ">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "75%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div className="close_icon">
                        <img src={CloseCircleSvg} alt="close-circle`" />
                      </div>
                    </div>
                  </div> */}
                  {urlData.length > 0 &&
                    urlData.map((value, i) => {
                      return (
                        <div key={i} className="add_new_progres">
                          <div className="upload_jpg">
                            <div className="doc_img">
                              <img src={LightSvg} alt="Light" />
                            </div>

                            <div key={i + "_images"} className="file_jpg_main">
                              <div className="file_jpg">
                                <h4>
                                  {furEditData?.name
                                    ? furEditData?.name
                                    : imgName}
                                </h4>
                                <h4>{100}%</h4>
                              </div>
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `100%` }}
                                  aria-valuenow={50}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                            <div
                              className="close_icon"
                              onClick={() => setUrlData([])}
                            >
                              <img src={CloseCircleSvg} alt="close-circle`" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className="editp_pet_field add_new_furnit_field">
              <div className="edit_pimg furnit_edit">
                <div className="my_store_img">
                  <img
                    src={
                      urlData?.length > 0
                        ? import.meta.env.VITE_APP_BASE_URL +
                          urlData[0]?.imageUrl
                        : StorebedImg
                    }
                    alt="furniture"
                  />
                </div>
              </div>
              <div className="edit_inner_pet furnit_edit_pet">
                <div className="editp_pet_field_inner">
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Enter Furniture Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Wooden Double Bed"
                      value={inputData.furnitureName}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          furnitureName: e.target.value,
                        })
                      }
                    />
                    {inputError && !inputData.furnitureName && (
                      <div className="error">Please enter furniture name!</div>
                    )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Furniture Category
                    </label>
                    
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={inputData.furnitureCat}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          furnitureCat: e.target.value,
                        })
                      }
                      disabled={!!categoryId}
                    >
                      {categoryId ? (
                        <option key={categoryId} value={categoryId}>
                          {
                            categoryData.find((item) => item.id === categoryId)
                              ?.name
                          }
                        </option>
                      ) : (
                        categoryData.map((value, key) => (
                          <option key={key} value={value.id}>
                            {value.name}
                          </option>
                        ))
                      )}
                    </select>

                    {inputError && !inputData.furnitureCat && (
                      <div className="error">
                        Please select your furniture category!
                      </div>
                    )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Furniture Price
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="$150"
                      onKeyDown={handleKeyPhone}
                      value={inputData.furniturePrice}
                      onKeyPress={(e) => {
                        if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          furniturePrice: e.target.value,
                        })
                      }
                    />
                    {inputError && !inputData.furniturePrice && (
                      <div className="error">
                        Please enter your furniture price!
                      </div>
                    )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Furniture Brand
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your furniture brand name"
                      value={inputData.furnitureBrand}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          furnitureBrand: e.target.value,
                        })
                      }
                    />
                    {inputError && !inputData.furnitureBrand && (
                      <div className="error">
                        Please enter your furniture brand name!
                      </div>
                    )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Furniture Material
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter your furniture material"
                      value={inputData.furnitureMaterial}
                      onKeyPress={(e) => {
                        if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          furnitureMaterial: e.target.value,
                        })
                      }
                    />
                    {inputError && !inputData.furnitureMaterial && (
                      <div className="error">
                        Please enter your furniture material!
                      </div>
                    )}
                  </div>
                  <div
                    className="profile_field"
                    // onClick={() => {
                    //   setOpenColorPicker(false);
                    // }}
                  >
                    <div
                      className="frunit_color"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {" "}
                        Furniture Color
                        <span>(click to pick color)</span>
                      </div>

                      {openColorPicker ? (
                        <div className="col_picer">
                          <button
                            type="button"
                            onClick={() => {
                              setOpenColorPicker(false);
                            }}
                          >
                            X
                          </button>
                          <ColorPicker
                            width={100}
                            height={100}
                            color={color}
                            onChange={setColor}
                            hideInput={["rgb", "hsv"]}
                            // hideHSV
                            // dark
                          />
                        </div>
                      ) : (
                        <button
                          className="picker_opener"
                          type="button"
                          style={{ background: color?.hex }}
                          onClick={() => {
                            setOpenColorPicker(true);
                          }}
                        ></button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="add_field add_new_furnit_desc_field">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Add Furniture Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Add Description"
                    value={inputData.furnitureDes}
                    onChange={(e) =>
                      setInputData({
                        ...inputData,
                        furnitureDes: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="sap_blue_btn">
                  {furEditData ? (
                    <Button
                      isLoading={buttonLoader}
                      onClick={() => handleEditFurniture()}
                    >
                      Save Changes
                    </Button>
                  ) : (
                    <Button
                      isLoading={buttonLoader}
                      onClick={() => handleSubmit()}
                    >
                      Save Details
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddNewFurniture;
