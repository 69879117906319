import { Fragment, useEffect } from "react";

import CustomisePlan from "./customisePlan/CustomisePlan";
import TestimonialSec from "../property/testimonialSec/TestimonialSec";

import "./Furniture.css";

const Furniture = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      {/*<HeroBannerSec data={data} />*/}
      <CustomisePlan />
      <TestimonialSec />
    </Fragment>
  );
};

export default Furniture;
