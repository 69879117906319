import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Card,
  ListGroup,
  ListGroupItem,
  Table,
} from "react-bootstrap";
import VisaImg from "../../assets/images/visaimg.png";
import MasterCardImg from "../../assets/images/mastercard.png";
import { format } from "date-fns";
import { useUserState } from "../../Contexts/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import PlusSvg from "../../assets/images/plus.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyCard from "../furniture/myBilling/MyCard";
const stripePromise = loadStripe("pk_test_Y680iCV5awEdWNzwLhXCVq76");
import { useContext } from "react";
import { UserContext } from "../../Contexts/UserContext";

const ModalComponent = ({
  showModal,
  setShowModal,
  handleCloseModal,
  cardData,
  FetchCardData,
  totalAmount,
  cartId,
  subscription_type,
  housePrice,
  setIsDisabledButton,
}) => {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [title, setTitle] = useState("Select Payment Method");
  const [selectedCardOption, setSelectedCardOption] = useState(-1);
  const [selectedCard, setSelectedCard] = useState(null);
  const [errMessage, setErrMessage] = useState("");
  const { setExpiryTiming } = useContext(UserContext);

  const headers = {
    Authorization: user?.token,
  };
  const FetchData = async (url, data) => {
    const baseCartUrl = `${import.meta.env.VITE_APP_BASE_URL}${url}`;

    try {
      const response = await axios.post(baseCartUrl, data, { headers });

      if (response.status === 200) {
        return response.data.data;
      }

      //Consider handling other status codes if needed.
    } catch (error) {
      console.error("Error during API request:", error);
      throw error; // Re-throw the error to propagate it up the call stack.
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (selectedCard) {
      setErrMessage("");
      setLoader(true);

      try {
        //  Create Payment Method
        const createPaymentMethodResponse = await FetchData(
          "/api/payment-method/create",
          {
            paymentMethod: selectedCard.id,
            amount: parseFloat(totalAmount).toFixed(2),
            cartId: cartId,
          }
        );

        // Check if the response from the create payment method API contains the expected 'id' property
        if (!createPaymentMethodResponse || !createPaymentMethodResponse.id) {
          console.error(
            "Error: Invalid response from create payment method API"
          );
          setLoader(false);
          return;
        }

        //  Confirm Payment
        const confirmPaymentResponse = await FetchData(
          "/api/payment-method/confirm",
          {
            paymentIntent: createPaymentMethodResponse.id,
            paymentMethod: selectedCard.id,
            subscription_type: subscription_type,
            housePrice: housePrice,
          }
        );

        if (createPaymentMethodResponse.id === confirmPaymentResponse.id) {
          setLoader(false);
          setExpiryTiming(true);
          navigate("/thanks");
        } else {
          setLoader(false);
        }
      } catch (error) {
        setErrMessage("Payment has failed!! Please try again.");
        console.error("Error handling card data:", error);
        setLoader(false);
      }
    } else {
      setErrMessage("Please select a card!!");
    }
  };

  const handleAddCard = (e) => {
    e.preventDefault();
    FetchCardData();
    setShowModal(false);
    setShowAddCardModal(true);
    handleCloseModal();
  };
  const getPaymentDetails = () => {
    //location.reload();
    setShowAddCardModal(false);
    setShowModal(true);
  };

  const handleOptionChange = (selectedOption, card) => {
    setErrMessage("");
    setSelectedCardOption(selectedOption);
    setSelectedCard(card);
  };
  useEffect(()=>{
    setIsDisabledButton(loader)
  },[loader])
  return (
    <>
      <style>
        {`
                .custom-card-list {
                list-style: none;
                padding: 0;
                }

                .custom-card-list li {
                display: flex;
                justify-content: space-between;
                }

                .uppercase {
                text-transform: uppercase;
                }
        `}
      </style>
      {loader ? (
        // <Loader />
        <>
        </>
      ) : (
        <>
          {" "}
          <Modal
            show={showModal}
            backdrop="static"
            onHide={() => {
              handleCloseModal();
              setErrMessage("");
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {cardData.length > 0 && cardData
                  ? "Select Payment Method"
                  : "No cards available"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="wrapper">
                {cardData?.length > 0 && cardData ? (
                  <div className="step-container">
                    <div className="step-body">
                      <form className="payments">
                        {cardData.map((card, i) => (
                          <div className="payment" key={i}>
                            <div className="payment-image">
                              <img
                                src={
                                  card.card.brand === "visa"
                                    ? "https://2.bp.blogspot.com/-E85z2lvyugo/Wxqgevf3MLI/AAAAAAAAA5Y/vfTQDWg8g9Ii--s6KhDC0yWb9_h7l47dwCLcBGAs/s1600/visa.png"
                                    : "https://1.bp.blogspot.com/-b_7SEUzJd4M/Wxqgerdr6xI/AAAAAAAAA5c/7b1znN3spZsGJUn7DlQh72hzbQoqmYEtACLcBGAs/s1600/mastercard.png"
                                }
                                alt="visa"
                              />
                            </div>
                            <input
                              className="payment-option"
                              id="payment-visa"
                              type="radio"
                              name="payment"
                              value={i}
                              checked={selectedCardOption === i}
                              onChange={() => handleOptionChange(i, card)}
                            />
                            <label
                              className="payment-label is-visa"
                              for="payment-visa"
                            >
                              {card.billing_details.name}{" "}
                              <span>**** **** **** {card.card.last4}</span>
                            </label>
                          </div>
                        ))}
                      </form>
                      {errMessage && (
                        <p className="text-danger text-center">{errMessage}</p>
                      )}
                      <div className="text-center use_card">
                        <Button
                          type="button"
                          className="btn btn-primary add_card "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={handleAddCard}
                        >
                          {/* <img src={PlusSvg} alt="plus" />  */}+ USE A NEW
                          CARD
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-screen">
                    <div className="text-center use_card">
                      <Button
                        type="button"
                        className="btn btn-primary add_card "
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={handleAddCard}
                      >
                        {/* <img src={PlusSvg} alt="plus" />  */}+ USE A NEW
                        CARD
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="druyp-outline" onClick={handleCloseModal}>
                Close
              </button>

              {cardData?.length > 0 ? (
                <button className="druyp-btn" onClick={handleConfirm}>
                  Confirm
                </button>
              ) : (
                ""
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={showAddCardModal}
            onHide={() => setShowAddCardModal(false)}
          >
            <div className="modal-body">
              <Elements stripe={stripePromise}>
                <MyCard
                  token={user?.token}
                  setTitle={setTitle}
                  getPaymentDetails={getPaymentDetails}
                />
              </Elements>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalComponent;
