import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";


// Create the UserContext
export const UserContext = createContext();

// Define the initial state for the user
const initialState = {
  user: null,
  isLoggedIn: false,
  unreadNotificationCount:0,

};

// Define the reducer function to handle actions
const userReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "UPDATE_NOTIFICATION_COUNT":
      return {
        ...state,
        unreadNotificationCount: action.payload,
      };
    default:
      return state;
  }
};

// Create a UserProvider component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const [furEditData, setFurEditData] = useState("");
  const [cleanEditData, setCleanEditData] = useState("");
  const [addCardModal, setAddCardModal] = useState(false);
  const[negotiationId,setNegotiationId]=useState()
  const[expiryTiming,setExpiryTiming]=useState(false)
  // Load user data from local storage on initial render
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      dispatch({ type: "LOGIN", payload: JSON.parse(storedUser) });
    }
  }, []);

  // Save user data to local storage whenever it changes
  useEffect(() => {
    if (state.isLoggedIn) {
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("isLoggedIn", true);
    } else {
      localStorage.removeItem("user");
      localStorage.setItem("isLoggedIn", false);
      
    }
  }, [state]);

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        furEditData,
        setFurEditData,
        cleanEditData,
        setCleanEditData,
        addCardModal, 
        setAddCardModal,
        negotiationId,
        setNegotiationId,
       expiryTiming,
       setExpiryTiming

      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Create custom hooks to access the context
export const useUserState = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context.state;
};

export const useUserDispatch = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context.dispatch;
};
