import React, { Fragment } from "react";
import Slider from "react-slick";
import "./HeroBannerStyle.css";

const HeroBannerSec = ({ data }) => {
  const settings = {
    dots: true,
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  
  return (
    <Fragment>
      <div className="banner-section">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <Slider {...settings}>
              {data?.houseImages &&
                data?.houseImages.length > 0 &&
                data?.houseImages?.map((image, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <img
                      src={import.meta.env.VITE_APP_BASE_URL + image.imageUrl}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroBannerSec;
