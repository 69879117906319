// import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";

const ThankYouPage = () => {
  const navigate = useNavigate();

  const {expiryTiming,setExpiryTiming}= useContext(UserContext)
  //setExpiryTiming(0)

 // setExpiryTiming(null)
  const handleHomeButton = () => {
    navigate("/");
  };
  return (
    <section className="thank-you-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <div className="thank-you-page-content">
              <h1>
                <span>Thank You</span> Your submission is received and we will
                contact you soon{" "}
              </h1>
              <a
                href="#"
                className="btn btn-primary arrow-icon"
                onClick={() => handleHomeButton()}
              >
                {" "}
                Go back to Homepage{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYouPage;
