import { Fragment } from "react";

import WhyChooseImg from "../../../assets/images/why-choose.png";
import VisitImg from "../../../assets/images/visit.png";
import HousePlanImg from "../../../assets/images/house-plan.png";
import PeaceImg from "../../../assets/images/peace.png";
import AmenitiesImg from "../../../assets/images/amenities.png";

import "./WhyChooseStyle.css";

const WhyChoose = () => {
  return (
    <Fragment>
      <div className="why-choose-us">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="heading-sec">
                <img src={WhyChooseImg} />
              </div>
            </div>
            <div className="col-12 col-lg-8">
              <div className="heading-sec inner">
                <h2>
                  Why Choose<span> Druyp.</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque euismod orci neque, nec fringilla ligula semper
                  at. Pellentesque euismod viverra est, sit amet placerat massa
                  interdum vel. Cras risus lacus, suscipit at turpis sit amet,
                  porttitor viverra lacus.
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={VisitImg} />
                    <h2>Visit Physically</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque euismod orci neque, nec fringilla ligula
                      semper at. Pellentesque euismod viverra est, sit amet
                      placerat massa interdum vel.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={HousePlanImg} />
                    <h2>Choose House Plan</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque euismod orci neque, nec fringilla ligula
                      semper at. Pellentesque euismod viverra est, sit amet
                      placerat massa interdum vel.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={PeaceImg} />
                    <h2>Live In Peace</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque euismod orci neque, nec fringilla ligula
                      semper at. Pellentesque euismod viverra est, sit amet
                      placerat massa interdum vel.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <div className="whychoose-sec">
                    <img src={AmenitiesImg} />
                    <h2>Amenities</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Pellentesque euismod orci neque, nec fringilla ligula
                      semper at. Pellentesque euismod viverra est, sit amet
                      placerat massa interdum vel.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WhyChoose;
