import { useState, useEffect, Fragment } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";

import { useUserState } from "../../Contexts/UserContext";

import HeroBannerSec from "./heroBannerSec/HeroBannerSec";
import PropertyDetailSec from "./propertyDetailsSec/PropertyDetailSec";
import SimilarPropertySec from "./similarTopPropertiesSec/SimilarProperty";
import TestimonialSec from "./testimonialSec/TestimonialSec";

import "./propertyDetail.css";

const PropertyDetail = () => {
  const { user } = useUserState();
  const { houseId } = useParams();
  const [data, setData] = useState({});
  const [dataLoader, setDataLoader] = useState(false);

  const [bookingStatus, setBookingstatus] = useState("");

  const fetchData = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    setDataLoader(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/house/get${
          user?.token ? "-booking-details" : ""
        }/${houseId}`,
        { headers: headersFormData }
      );
      setData(response?.data?.data);
      setBookingstatus(response?.data.data?.booking_details?.booking_status);
      setDataLoader(false);
    } catch (error) {
      setDataLoader(false);
      console.log("Error while getting property data", error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      {dataLoader ? (
        <div class="eye-loader-frame m-5 p-5">
          <div class="eye-loader-spinner"></div>
        </div>
      ) : (
        <>
          <HeroBannerSec data={data} />
          <PropertyDetailSec data={data} />
        </>
      )}

      {/* <div className="top-property-sec details">
        <SimilarPropertySec />
      </div> */}
      <TestimonialSec />
    </Fragment>
  );
};

export default PropertyDetail;
