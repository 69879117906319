import React, { Fragment, useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";

import CartBackImg from "../assets/images/cart-back.png";
import MailMessageImg from "../assets/images/mailmessage.png";
import InstaImg from "../assets/images/instagram.png";
import TelePhoneImg from "../assets/images/telephone.png";
import TwitterImg from "../assets/images/twitter.png";

const ContactUs = () => {
  const [inputData, setInputData] = useState({
    fullName: "",
    company: "",
    email: "",
    contactNumber: "",
    msg: "",
  });
  const [formError, setFormError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, company, email, contactNumber, msg } = inputData;

    if (
      !fullName ||
      !company ||
      !contactNumber ||
      contactNumber?.length < 10 ||
      !email ||
      !emailRegex.test(email)
    ) {
      setFormError(true);
    } else {
      setFormError(false);
      try {
        setBtnLoader(true);
        let payload = {
          full_name: fullName,
          company: company,
          email: email,
          phone_number: contactNumber,
          message: msg,
        };
        const response = await fetch(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/contact-us`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (response?.ok) {
          setSuccessMsg("Successfully Submitted");
        }
        setBtnLoader(false);
        setInputData({
          fullName: "",
          company: "",
          email: "",
          contactNumber: "",
          msg: "",
        });
      } catch (error) {
        console.log("Error while hitting sign up API", error);
        setBtnLoader(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      <div className="banner-section cart-banner contact">
        <img src={CartBackImg} alt="..." />
        <h2>Contact us</h2>
      </div>

      <div className="contact-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="cont-content">
                <div className="foot_head">Get in Touch with Us</div>
                <ul>
                  <li>
                    <a href="#">
                      <img src={TelePhoneImg} alt="contactIcon" />
                      <div className="details">
                        Phone No. <span>(217) 443-4545</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={MailMessageImg} alt="contactIcon" />
                      <div className="details">
                        Email <span>info@druyp.com</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={InstaImg} alt="contactIcon" />
                      <div className="details">
                        Instagram <span>@druyp_insta</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={TwitterImg} alt="contactIcon" />
                      <div className="details">
                        Twitter <span>@druyp_twitter</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="contact-content">
                <h2 className="xl-font">Contact Us</h2>
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="contact-form"
                >
                  <div className="contact-form">
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          className="form-control me-2"
                          type="text"
                          placeholder="Full Name"
                          value={inputData?.fullName}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              fullName: e.target.value,
                            })
                          }
                        />
                        {formError && !inputData?.fullName && (
                          <span className="error">
                            Please enter your full name
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control me-2"
                          type="text"
                          placeholder="Company"
                          value={inputData?.company}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z \s]+$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              company: e.target.value,
                            })
                          }
                        />
                        {formError && !inputData?.company && (
                          <span className="error">
                            Please enter your company name
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control me-2"
                          type="text"
                          placeholder="Business Email"
                          value={inputData?.email}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              email: e.target.value,
                            })
                          }
                        />
                        {formError &&
                          (!inputData?.email ||
                            !emailRegex.test(inputData?.email)) && (
                            <span className="error">
                              {!inputData?.email
                                ? " Please enter your business email"
                                : !emailRegex.test(inputData?.email)
                                ? "Invalid email address"
                                : ""}
                            </span>
                          )}
                      </div>
                      <div className="col-md-6">
                        <input
                          className="form-control me-2"
                          type="tel"
                          placeholder="Contact Number"
                          maxlength={10}
                          value={inputData?.contactNumber}
                          onKeyPress={(e) => {
                            if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              contactNumber: e.target.value,
                            })
                          }
                        />
                        {formError &&
                          (!inputData?.contactNumber ||
                            inputData?.contactNumber?.length < 10) && (
                            <span className="error">
                              {!inputData?.contactNumber
                                ? " Please enter your contact number"
                                : inputData?.contactNumber?.length < 10
                                ? "Invalid contact number"
                                : ""}
                            </span>
                          )}
                      </div>
                      <div className="col-md-12">
                        <textarea
                          className="form-control"
                          placeholder="Your Message or Interest?"
                          rows="3"
                          value={inputData?.msg}
                          onChange={(e) =>
                            setInputData({
                              ...inputData,
                              msg: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div className="login_form">
                      <button
                        type="submit"
                        className="blue-button"
                        disabled={btnLoader}
                      >
                        Send Us {btnLoader && <Spinner />}
                      </button>
                    </div>
                    {successMsg && (
                      <span className="success-alert">{successMsg}</span>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
