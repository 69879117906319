import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserState } from "../../../../Contexts/UserContext";

import { useNavigate } from "react-router-dom";

import CleaningOtherPlans from "../cleaning-other-plans";

function CleaningPlan() {
  const { user } = useUserState();
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [showButton, setShowButton] = useState(false);
  const [displayedCompanies, setDisplayedCompanies] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const navigate = useNavigate();

  const getAllCompanies = async () => {
    const headers = {
      Authorization: user?.token,
    };

    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/user/getAllCleaningWithServiceCompany`,
        { headers }
      );
      setAllCompanies(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const ButtonSelectedCompany = (e, ele) => {
    if (e.target.checked) {
      setCompanyData(ele?.CleaningService);
      setSelectedCompany(e.target.value);
      setShowButton(true);
    }
  };

  const handleProceedButton = () => {
    navigate("/cleaning/package", { state: selectedCompany });
  };

  const handleViewMore = () => {
    const startIndex = displayedCompanies.length;
    const endIndex = startIndex + 3;
    setDisplayedCompanies([
      ...displayedCompanies,
      ...allCompanies.slice(startIndex, endIndex),
    ]);
  };

  useEffect(() => {
    getAllCompanies();
  }, [user?.token]);

  useEffect(() => {
    if (allCompanies.length > 0) {
      setDisplayedCompanies(allCompanies.slice(0, 3));
    }
  }, [allCompanies]);



  return (
    <div className="cleaning-company" id="cleaning-company">
      <div className="container-fluid my-container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <b className="text-danger text-center">
                    There is a mandatory monthly cleaning package required
                  </b>
            <div className="search-header">
              <h2>Choose Cleaning trusted Partners</h2>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="modal-content">
              <div className="modal-header"></div>
              <div className="modal-body">
                <div className="negotiate-content">
                  <div className="">
                    <div className="row">
                      {displayedCompanies && displayedCompanies.length > 0 ? (
                        displayedCompanies.map((ele) => (
                          <div
                            className={`col-12 col-lg-4 col-md-4 ${
                              selectedCompany === ele?.id
                                ? "active-company"
                                : ""
                            }`}
                            key={ele?.id}
                          >
                            <div className="trusted-part-title">
                              <h2>{ele?.cleaning_company}</h2>
                            </div>
                            <div className="trusted-part-radio">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={ele?.id}
                                  value={ele?.id}
                                  onChange={(e) => ButtonSelectedCompany(e, ele)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={ele?.id}
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div class="eye-loader-frame m-5 p-5">
                <div class="eye-loader-spinner"></div>
                </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {showButton && (
          <div className=" sap_prem_btn cleaning text-center mt-4">
            <Button onClick={handleProceedButton}>Proceed</Button>
          </div>
        )} */}
        {allCompanies.length > displayedCompanies.length && (
          <button className="druyp-link" onClick={handleViewMore}>
            View More
          </button>
        )}
        {showButton && (
          <CleaningOtherPlans serviceCompanyId={selectedCompany} companyServices={companyData}/>
        )}
      </div>
    </div>
  );
}

export default CleaningPlan;
