import { Fragment } from "react";

import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import BedImage from "../../../assets/images/Bed.svg";
import BathTubSvg from "../../../assets/images/Bathtub.svg";
import RulerSvg from "../../../assets/images/ruler.svg";
import heart from "@images/heart.svg";
import redheart from "@images/heart-red.svg";
import { useUserState, useUserDispatch } from "../../../Contexts/UserContext";
import { HouseContext } from "../../../Contexts/HouseContext";

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import HouseDummyImg from "../../../assets/images/houseDummy.webp";

const TopPropertiesSec = () => {
  const [imageSrc, setImageSrc] = useState(heart);
  const navigate = useNavigate();
  const { user, isLoggedIn } = useUserState();
  const [topProperty, setTopProperty] = useState([]);
  const [apiLoader, setApiLoader] = useState(false);

  const headers = {
    Authorization: user?.token,
  };

  const getHouseList = async () => {
    setApiLoader(true);
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/house/get-by-user`,
        {
          headers: headers,
        }
      );
      setTopProperty(res?.data?.data);
      setApiLoader(false);
    } catch (error) {
      console.log(
        "Error while fetching Owner properties on home page",
        error?.message
      );
      setApiLoader(false);
    }
  };

  const fetchData = async () => {
    setApiLoader(true);
    try {
      const apiUrl = isLoggedIn
        ? `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/house/get-favorite-house-user`
        : `${import.meta.env.VITE_APP_BASE_URL}/api/house/get-list`;

      const res = await axios.get(apiUrl, {
        headers: headers,
      });
      setTopProperty(res.data.data);
      setApiLoader(false);
    } catch (error) {
      console.log(
        "Error while fetching all properties on home page",
        error?.message
      );
      setApiLoader(false);
    }
  };

  const addFav = async (parameter) => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house/favorite-house/${parameter}`,
        {
          headers: headers,
        }
      );
      if (response) {
        if (response.data.message == true) {
          setImageSrc(redheart);
        } else {
          setImageSrc(heart);
        }
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    if (user?.currentRole === "home_owner") {
      getHouseList();
    } else {
      fetchData();
    }
  }, [user?.token, user]);

  const settings = {
    dots: true, // Enable indicators
    slidesToShow: 4,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023, // Small screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991, // Small screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767, // Small screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 499, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleImgLoadingError = (e) => {
    // default image add, if associated image is not available...
    e.target.src = HouseDummyImg;
  };

  return (
    <Fragment>
      <div className="container-fluid my-container">
        <div className="slide_main">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div className="top-property">
              <h2>
                Top <span className="top_pro_span">Properties</span>
              </h2>
            </div>
            <div className="top-property-button">
              <Link to={`/properties`}>View all properties</Link>
            </div>
          </div>

          {apiLoader ? (
            <div class="eye-loader-frame">
              <div class="eye-loader-spinner"></div>
            </div>
          ) : (
            <Slider {...settings}>
              {topProperty &&
                topProperty.length > 0 &&
                topProperty.map((property, index) => (
                  <div key={index} className="property-sec">
                    <div className="property-inner">
                      <span className="type-pro">
                        {property?.house_facility === "Customize"
                          ? "Unfurnished"
                          : property?.house_facility}
                      </span>
                      <Link to={`/property_detail/${property.id}`}>
                        {property.firstImage ? (
                          <img
                            src={
                              import.meta.env.VITE_APP_BASE_URL +
                              property.firstImage
                            }
                            alt={`Property ${index + 1}`}
                            onError={(e) => handleImgLoadingError(e)}
                          />
                        ) : (
                          <img
                            src={HouseDummyImg}
                            alt={`Property ${index + 1}`}
                          />
                        )}
                      </Link>
                      <div className="property-content">
                        <div className="property-price">
                          <p className="pricing">
                            ${numberWithCommas(property.monthly_price+property?.total_utilities_price)}/
                            <sub>month</sub>
                          </p>
                          <a
                            className={`like-sec ${
                              property?.isFavorite ? "like" : ""
                            }`}
                            onClick={() => {
                              if (user) {
                                addFav(property.id);
                              } else {
                                navigate("/login");
                              }
                            }}
                          >
                            <i className="fa fa-heart-o"></i>
                          </a>
                        </div>
                        <div className="property-discription">
                          <p>{property.name.toUpperCase()}</p>
                          <p>
                            {/* {property.full_address
                            ?.split(" ")
                            ?.slice(0, 5)
                            .join(" ") +
                            (property.full_address?.split(" ").length > 5
                              ? "..."
                              : "")} */}
                          </p>
                          {/* <b>{property?.state?.toUpperCase()}</b> */}
                        </div>
                        <div className="property-overview">
                          <ul>
                            <li>
                              <img src={BedImage} alt="Bed" />{" "}
                              {property.bedroom_no} Bedroom
                            </li>
                            <li>
                              <img src={BathTubSvg} alt="Bathroom" />{" "}
                              {property.bathroom_no} Bathroom
                            </li>
                            <li>
                              <img src={RulerSvg} alt="Square Feet" />
                              {property.area} SQ FT
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TopPropertiesSec;
