import { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Spinner } from "react-bootstrap";
import account from "../assets/images/account.png";
import account2 from "../assets/images/account2.png";
import profile from "../assets/images/profile.png";
import Edit from "../assets/images/Edit.svg";
import call from "../assets/images/call.png";
import message from "../assets/images/message.png";
import "./custom.css";
import "./styles.css";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import convertToTitleCase from "../utils/roleHelper";

const UserProfile = ({ toggleComponent }) => {
  const { user, isLoggedIn } = useUserState();
  const [roles, setRoles] = useState([]);
  const [errorCheck, setErrorCheck] = useState(false);
  const [extentRoleModal, setExtentRoleModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useUserDispatch();

  const handleRolesCheckbox = (e) => {
    const role = e.target.value;
    if (e.target.checked) {
      setRoles([...roles, role]); // Add role to the state
    } else {
      setRoles(roles.filter((r) => r !== role)); // Remove role from the state
    }
  };

  const addRoles = async () => {
    if (roles?.length <= 0) {
      setErrorCheck(true);
    } else {
      setLoader(true);
      const headersFormData = {
        "Content-Type": "application/json",
        Authorization: user?.token,
      };
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/add-remove-user-role`,
          { role: roles },
          { headers: headersFormData }
        );
        if (response?.data?.statusCode === 200) {
          setLoader(false);
          setExtentRoleModal(false);

          const userUpdated = {
            ...user,
            role: [...user?.role, ...roles],
          };
          dispatch({ type: "LOGIN", payload: userUpdated });
        }
      } catch (error) {
        console.log("error", error);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  },[]);

  return (
    <>
      <div className="main-box">
        <div className="content-header">
          <h3>Your Profile</h3>
          <a className="text-white" onClick={toggleComponent}>
            Edit Profile <img src={Edit} alt="Edit" />
          </a>
        </div>
        <div className="content-middle">
          <div className="profile-sec">
            <img src={user?.profile_img ? user?.profile_img : profile} />
          </div>
          <div className="profile-content">
            <ul>
              <li>
                <span>
                  <img src={account} alt="account" />
                  Profile Type
                </span>
                {convertToTitleCase(user?.currentRole)}
              </li>
              <li>
                <span>
                  <img src={call} alt="call" />
                  Phone No.
                </span>
                XXXXXXX
                {user?.phone_number.substring(7, user?.phone_number.length)}
              </li>
              <li>
                <span>
                  <img src={account2} alt="account2" />
                  Name
                </span>
                {user?.first_name + " "}
                {user?.last_name && user?.last_name}
              </li>
              <li>
                <span>
                  <img src={message} alt="message" />
                  Email Address
                </span>
                {user?.email}
              </li>
              {user?.role?.length < 3 &&
               !user?.role?.includes("furniture_company") &&
               !user?.role?.includes("cleaning_company") &&
               !user?.role?.includes("relo") &&
              (
                <li>
                  <hr />
                  <span>
                    <img src={account} alt="message" />
                    Extend your profile to additional roles
                  </span>
                  <div>
                    {!user?.role?.includes("tenant") && (
                      <div className="user-p-new">
                        <p>Tenant</p>
                        <input
                          type="checkbox"
                          value="tenant"
                          onChange={(e) => handleRolesCheckbox(e)}
                        />
                      </div>
                    )}
                    {!user?.role?.includes("home_owner") && (
                      <div className="user-p-new">
                        <p>Home Owner</p>
                        <input
                          type="checkbox"
                          value="home_owner"
                          onChange={(e) => handleRolesCheckbox(e)}
                        />
                      </div>
                    )}
                    {!user?.role?.includes("property_manager") && (
                      <div className="user-p-new">
                        <p>Property manager</p>
                        <input
                          type="checkbox"
                          value="property_manager"
                          onChange={(e) => handleRolesCheckbox(e)}
                        />
                      </div>
                    )}
                    {errorCheck && roles?.length <= 0 && (
                      <span className="text-danger">
                        Please select the role
                      </span>
                    )}
                    {user?.role?.length < 3 && (
                      <div>
                        <button
                          className="druyp-btn"
                          type="button"
                          onClick={() => {
                            if (roles?.length <= 0) {
                              setErrorCheck(true);
                            } else {
                              setExtentRoleModal(true);
                            }
                          }}
                        >
                          Save Details
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <Modal
        show={extentRoleModal}
        onHide={() => setExtentRoleModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Extend Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to extent your profile to additional roles
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setExtentRoleModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={loader}
            onClick={() => addRoles()}
          >
            Save Details
            {loader && <Spinner />}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfile;
