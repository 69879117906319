import { Fragment, useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { useUserState } from "../Contexts/UserContext";

import CleaningBack from "../assets/images/cleaning-back.png";
import Gplay from "../assets/images/gplay.png";
import AppStore from "../assets/images/app-store.png";
import DruypMobile from "../assets/images/druyp-mobile.png";
import BasicCleaning from "../assets/images/basic-cleaning1.png";
import PremiumCleaning from "../assets/images/premium-cleaning1.png";
import DeluxCleaning from "../assets/images/delux-cleaning1.png";

const CleaningPackageList = () => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const [plansList, setPlansList] = useState([]);

  const getCleaningPlans = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    try {
      const res = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/cleaning-service-category/get-list`,
        { headers: headersFormData }
      );
      if (res) {
        setPlansList(res?.data?.data);
      }
      
    } catch (error) {
      console.log("Error while fetching cleaning categories", error?.message);
    }
  };

  useEffect(() => {
    getCleaningPlans();
  }, [user?.token]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    if (
      !location?.state?.houseId ||
      !location?.state?.cartId ||
      !location?.state?.startDate ||
      !location?.state?.endDate
    ) {
      navigate("/");
    }
  }, []);

  return (
    <Fragment>
      <div className="banner-section customise-furniture-back">
        <img src={CleaningBack} alt="..." />
      </div>
      <div className="banner-section customise-furniture-back">
        <div
          className="all-property-sec customise-furniture-sec"
          id="cleaning_package"
        >
          <div className="container-fluid my-container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="search-header">
                  <h2>
                    Choose <span>Cleaning </span>package
                  </h2>
                </div>
              </div>
              {plansList &&
                plansList?.map((ele) => {
                  return (
                    <div
                      className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                      key={ele?.id}
                    >
                      <div
                        className="property-inner furniture-content"
                        onClick={() =>
                          navigate("/cleaning/company", {
                            state: {
                              packageArr: {
                                package_name: ele?.name,
                                planId: ele?.id,
                                price: 0,
                                qty: "1",
                                status: "1",
                                cartId: location?.state?.cartId,
                                houseId: location?.state?.houseId,
                                startDate: location?.state?.startDate,
                                endDate: location?.state?.endDate,
                              },
                            },
                          })
                        }
                      >
                        <img
                          src={
                            ele?.name === "Basic Cleaning"
                              ? BasicCleaning
                              : ele?.name === "Premium Cleaning"
                              ? PremiumCleaning
                              : ele?.name === "Delux Cleaning"
                              ? DeluxCleaning
                              : ""
                          }
                        />
                        <div className="property-content">
                          <div className="property-discription">
                            <h4>{ele?.name}</h4>
                            <a className="btn btn-primary">Choose plan</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="property-inner furniture-content" onClick={() =>
                          navigate("/cleaning/company", {
                            state: {
                              packageArr: {
                                package_name: "Basic",
                                planId: "3",
                                price: "1000",
                                qty: "1",
                                status: "1",
                                cartId: location?.state?.cartId,
                                houseId: location?.state?.houseId,
                              },
                            },
                          })
                        }>
                  <img src={BasicCleaning} />
                  <div className="property-content">
                    <div className="property-discription">
                      <h4>Basic</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                      </p>
                      <a
                        className="btn btn-primary"
                        
                      >
                        Choose plan
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="property-inner furniture-content" onClick={() =>
                          navigate("/cleaning/company", {
                            state: {
                              packageArr: {
                                package_name: "Premium",
                                planId: "2",
                                price: "2000",
                                qty: "1",
                                status: "1",
                                cartId: location?.state?.cartId,
                                houseId: location?.state?.houseId,
                              },
                            },
                          })
                        }>
                  <img src={PremiumCleaning} />
                  <div className="property-content">
                    <div className="property-discription">
                      <h4>Premium</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                      </p>
                      <a
                        className="btn btn-primary"
                        
                      >
                        Choose plan
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                <div className="property-inner furniture-content"  onClick={() =>
                          navigate("/cleaning/company", {
                            state: {
                              packageArr: {
                                package_name: "Delux",
                                planId: "1",
                                price: "3000",
                                qty: "1",
                                status: "1",
                                cartId: location?.state?.cartId,
                                houseId: location?.state?.houseId,
                              },
                            },
                          })
                        }>
                  <img src={DeluxCleaning} />
                  <div className="property-content">
                    <div className="property-discription">
                      <h4>Delux</h4>
                      <p>
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                      </p>
                      <a
                        className="btn btn-primary"
                        
                      >
                        Choose plan
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <div className="mandat_para">
                  <b className="text-danger text-center">
                  Mandatory Monthly packages is required and automatically added to the services.
                  </b>
                  <button
                    type="button"
                    className="druyp-btn"
                    onClick={() =>
                      navigate("/cleaning/company", {
                        state: {
                          packageArr: {
                            cartId: location?.state?.cartId,
                            houseId: location?.state?.houseId,
                            startDate: location?.state?.startDate,
                            endDate: location?.state?.endDate,
                          },
                        },
                      })
                    }
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials-sec app-promo">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-12 col-lg-7 col-md-7">
              <div className="app-content">
                <h2>
                  Download<span> Our App</span>
                </h2>
                <p>
                  Find everything you need for buying, selling & renting
                  property in our new Finder App!
                </p>
                <div className="app-button-sec">
                  <a href="#">
                    <img src={Gplay} />
                  </a>
                  <a href="#">
                    <img src={AppStore} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 col-md-5">
              <div className="app-img">
                <img src={DruypMobile} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CleaningPackageList;
