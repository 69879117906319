import React from "react";

import CleaningBGImg from "@images/cleaning-back.png";
import AboutCleanImg from "@images/about-clean.png";
import TickmarkImg from "@images/tickmark.svg";

function Banner() {
  return (
    <div className="banner-section furniture-main">
      <img src={CleaningBGImg} alt="..." />
      <div className="search-home my-container">
        <div className="cleaning furniture-about">
          <div className="furniture-img">
            <img src={AboutCleanImg} />
          </div>
          <div className="fur-about-content">
            <h2>We Provide Best Cleaning Service Provider</h2>
            <p>
              We provide a range of contract cleaning UK services across a
              variety of sectors including factory, laboratory, educational and
              commercial.
            </p>
            <div className="overview-amenities">
              <ul>
                <li>
                  <img src={TickmarkImg} />
                  We Treat Your Homes Like Ours
                </li>
                <li>
                  <img src={TickmarkImg} />
                  Satisfaction Guaranteed
                </li>
                <li>
                  <img src={TickmarkImg} />
                  Immediate Online Quotes
                </li>
                <li>
                  <img src={TickmarkImg} />
                  Regular & Monthly Cleaning
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
