// import { DatePicker } from "antd";
// const { RangePicker } = DatePicker;
// const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000;
// const currentDate = new Date();

// const RangeDateCalendar = ({
//   selectedStartDate,
//   setSelectedStartDate,
//   selectedEndDate,
//   setSelectedEndDate,
//   disabledDate,
//   setDisabledDate,
//   disableMonths,
// }) => {
//   const handleCalendarChange = (dates) => {
//     if (!dates) {
//       setSelectedStartDate(null);
//       setSelectedEndDate(null);
//       setDisabledDate(null);
//       return;
//     }
//     const [start, end] = dates;

//     // Check if a start date is selected
//     if (start) {
//       // Disable dates within the next 29 days from the selected start date
//       setSelectedStartDate(start);
//       setSelectedEndDate(end);
//       if (disableMonths) {
//         const disabledEndDate = new Date(start);
//         disabledEndDate.setMonth(
//           disabledEndDate.getMonth() + parseInt(disableMonths)
//         );
//         disabledEndDate.setDate(disabledEndDate.getDate() - 1);
//         console.log("disabledEndDate", disabledEndDate);
//         setDisabledDate(disabledEndDate);
//       } else {
//         const disabledEndDate = new Date(start);
//         const totalDayInMonths =
//           new Date(
//             disabledEndDate.getFullYear(),
//             disabledEndDate.getMonth() + 1,
//             0
//           ).getDate() - 1;
//         disabledEndDate.setDate(disabledEndDate.getDate() + totalDayInMonths);
//         setDisabledDate(disabledEndDate);
//       }
//     } else {
//       // Clear selected dates if no start date is selected
//       setSelectedStartDate(null);
//       setSelectedEndDate(null);
//       setDisabledDate(null);
//     }
//   };

//   return (
//     <div>
//       <RangePicker
//         onCalendarChange={handleCalendarChange}
//         value={[selectedStartDate, selectedEndDate]}
//         disabledDate={(current) =>
//           selectedStartDate
//             ? current < selectedStartDate || current < disabledDate
//             : current < new Date(currentDate.getTime() + threeDaysInMillis)
//         }
//         //open={selectedEndDate ? ["end"] : undefined}
//       />
//     </div>
//   );
// };

// export default RangeDateCalendar;

// old code
import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const checkDateOverlap = (dates, startdate2, endDate2) => {
  if (!dates || !startdate2 || !endDate2 || !Array.isArray(dates)) return false;
  // Convert second range dates to Date objects
  startdate2 = new Date(startdate2);
  endDate2 = new Date(endDate2);
  // Check if either of the second range dates is invalid
  if (
    isNaN(startdate2.getTime()) ||
    isNaN(endDate2.getTime()) ||
    startdate2 >= endDate2
  ) {
    throw new Error("Invalid or inverted date range provided.");
  }

  // Iterate over each date range in the array
  for (const dateRange of dates) {
    // Convert date range start and end dates to Date objects
    const startDate1 = new Date(dateRange.start);
    const endDate1 = new Date(dateRange?.end);

    // Check if either of the first range dates is invalid
    if (
      isNaN(startDate1.getTime()) ||
      isNaN(endDate1.getTime()) ||
      startDate1 >= endDate1
    ) {
      throw new Error("Invalid or inverted date range provided in the array.");
    }

    // Check for overlap
    if (startDate1 < endDate2 && endDate1 > startdate2) {
      return {
        startDate: new Date(startDate1).toLocaleDateString("en-US"),
        endDate: new Date(endDate1).toLocaleDateString("en-US"),
      }; // Overlap found, return true
    }
  }

  return false; // No overlap found
};

const RangeDateCalendar = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  disabledDateRanges,
  //   disabledDate,
  //   setDisabledDate,
  disableMonths,
  houseType,
}) => {
  const dateFormat = "YYYY/MM/DD";

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const initialDisabledDays =
    houseType === "Fully Furnished" ? 2 : houseType === "Extended" ? 0 : 4;
  const [disabledDays] = useState(initialDisabledDays);
  const[disabledSelectedDate,setDisabledSelectedDate] = useState([false,false]);
  const handleCalendarChange = (dates) => {
    if (!dates) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setStartDate(null);
      setEndDate(null);
      return;
    }
    const [start, end] = dates;
    // Check if a start date is selected
    const today = new Date();
    const todayPlus3 = new Date();
    todayPlus3.setHours(0, 0, 0, 0);
    todayPlus3.setDate(today.getDate() + Number(initialDisabledDays));
    if (start && start < todayPlus3) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      // setStartDate(null);
      // setEndDate(null);
      setErrorMessage(
        `Start date cannot be earlier than ${initialDisabledDays} days from today.`
      );
      return;
    }

    if (start) {
      setStartDate(start);
    }
    if (end) {
      setEndDate(end);
    }
    if (!start && !end) {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const setAndFormatDate = (date, setSelectedFunction) => {
    if (date) {
      const formattedDate = new Date(date);
      formattedDate.setHours(0, 0, 0, 0);
      setSelectedFunction(formattedDate.toISOString());
    } else {
      setSelectedFunction(null);
    }
  };

  const disabledDate = (current) => {
    const currentDate = new Date(current);
    const today = new Date();
    const todayPlus3 = new Date(today);
    todayPlus3.setHours(0, 0, 0, 0);
    todayPlus3.setDate(todayPlus3.getDate() + Number(initialDisabledDays));

    // Check if current date is today or today + 3
    if (currentDate < todayPlus3) {
      return true;
    }

    // Check disabledDateRanges
    // if (
    //   disabledDateRanges?.some(
    //     ({ start, end }) => currentDate >= start && currentDate <= end
    //   )
    // ) {
    //   return true;
    // }
    if (
      disabledDateRanges?.some(({ start, end }) => {
        const endDatePlus = new Date(end);
        endDatePlus.setDate(endDatePlus.getDate() + Number(disabledDays) - 1);
        return currentDate >= start && currentDate <= endDatePlus;
      })
    ) {
      return true;
    }

    // Check if start date is selected
    if (startDate) {
      const minEndDate = new Date(startDate);
      minEndDate.setMonth(minEndDate.getMonth() + Number(disableMonths));
      minEndDate.setDate(minEndDate.getDate() - 1);
      return currentDate < minEndDate;
    }

    return false;
  };

  useEffect(() => {
    //  console.log("Working")
       houseType === "Extended" ? setDisabledSelectedDate([true, false]) : null;

    if (selectedStartDate) {
      const formattedDate = new Date(selectedStartDate);
      formattedDate.setHours(0, 0, 0, 0);
      setStartDate(dayjs(formattedDate.toISOString(), dateFormat).add(1, "day"));
    }
  }, []);

  // if (checkDateOverlap(disabledDateRanges))
  useEffect(() => {
    try {
      const overlap = checkDateOverlap(disabledDateRanges, startDate, endDate);
      if (overlap) {
        setErrorMessage(
          `House already booked from ${overlap.startDate} to ${overlap.endDate}`
        );
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        //  setStartDate(null);
        //  setEndDate(null);
      } else {
        setAndFormatDate(startDate, setSelectedStartDate);
        setAndFormatDate(endDate, setSelectedEndDate);
        setErrorMessage(null);
      }
    } catch (error) {
      setSelectedStartDate(null);
      setSelectedEndDate(null);
      setStartDate(null);
      setEndDate(null);
      setErrorMessage(`${error.message}`);
      console.error("Error:", error.message);
    }
  }, [disabledDateRanges, startDate, endDate]);
  return (
    <div>
      <RangePicker
        // defaultValue={[dayjs('2024-08-29T18:30:00.000Z', dateFormat), ]}
        onCalendarChange={handleCalendarChange}
        value={[startDate, endDate]}
        disabledDate={(current) => disabledDate(current)}
        disabled={disabledSelectedDate}
      />
      {errorMessage && (
        <div className="sap-val-error text-danger mb-0 mt-1">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default RangeDateCalendar;
