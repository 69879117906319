import Spinner from "react-bootstrap/Spinner";

const Loader = () => {
  return (
    <Spinner
      className="d-flex align-items-center justify-content-center "
      animation="border"
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default Loader;
