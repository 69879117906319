import { Fragment } from "react";

import Slider from "react-slick";

import UserImg from "../../../assets/images/user.png";

import "./TestimonialsSec.css";

const TestimonialsSec = () => {
  const settings = {
    dots: true, // Enable indicators
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023, // Small screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 499, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <div className="testimonials-sec">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="heading-sec new">
                <h2>
                  What <span>Our Client</span> Says
                </h2>
                <p>
                  Amet libero morbi venenatis ut est. Iaculis leo ultricies nunc
                  id ante adipiscing. Vel metus odio at faucibus ac.Neque id
                  placerat et id ut. Scelerisque eu mi ullamcorper sit urna. Est
                  volutpat dignissim nec.
                </p>
              </div>
            </div>
            <Slider {...settings}>
              <div className="carousel-item active">
                <div className="slider-sec">
                  <div className="slider-inner">
                    <img src={UserImg} />
                    <div className="slider-content">
                      <p>
                        Amet libero morbi venenatis ut est. Iaculis leo
                        ultricies nunc id ante adipiscing. Vel metus odio at
                        faucibus ac.Neque id placerat et id ut. Scelerisque eu
                        mi ullamcorper sit urna. Est volutpat dignissim nec.
                      </p>
                      <ul>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                      <h5>Carolyne Ortiz </h5>
                      <p>Corporate Officer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slider-sec">
                  <div className="slider-inner">
                    <img src={UserImg} />
                    <div className="slider-content">
                      <p>
                        Amet libero morbi venenatis ut est. Iaculis leo
                        ultricies nunc id ante adipiscing. Vel metus odio at
                        faucibus ac.Neque id placerat et id ut. Scelerisque eu
                        mi ullamcorper sit urna. Est volutpat dignissim nec.
                      </p>
                      <ul>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                      <h5>Carolyne Ortiz </h5>
                      <p>Corporate Officer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="slider-sec">
                  <div className="slider-inner">
                    <img src={UserImg} />
                    <div className="slider-content">
                      <p>
                        Amet libero morbi venenatis ut est. Iaculis leo
                        ultricies nunc id ante adipiscing. Vel metus odio at
                        faucibus ac.Neque id placerat et id ut. Scelerisque eu
                        mi ullamcorper sit urna. Est volutpat dignissim nec.
                      </p>
                      <ul>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                      <h5>Carolyne Ortiz </h5>
                      <p>Corporate Officer</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TestimonialsSec;
