import furnithistparkImage from "@images/furnithistpark.png";
import furnitfridaImage from "@images/furnitfrida.png";
import furnitfrida2Image from "@images/furnitfrida2.png";
import account2Image from "@images/account2.png";
import callImage from "@images/call.png";
import messageImage from "@images/message.png";
import locationPinImage from "@images/location pin.png";
import basiccleanhistimgImage from "@images/basiccleanhistimg.png";
import deluxcleanhistimgImage from "@images/deluxcleanhistimg.png";

const CleaningHistory = () => {
  return (
    <div className="main-box">
      <div className="content-header">
        <h3>My History</h3>
      </div>
      <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnithistparkImage} alt="furnithistpark" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Peter Parker</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={basiccleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Basic Cleaning Plan</h4>
              <h4>Date : 1 Mar 2022 to 20 July 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Basic Cleaning Services</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnitfridaImage} alt="furnitfrida" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Frida</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={deluxcleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Delux Cleaning Plan</h4>
              <h4>Date : 1 Apr 2022 to 30 Dec 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Basic Cleaning Services</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
      <div className="furnit_hist">
        <div className="furnit_hist_left">
          <div className="furnit_book_profile_cont">
            <div className="profile-sec">
              <img src={furnitfrida2Image} alt="furnitfrida2" />
            </div>
            <ul className="furnit_book_inner_cont">
              <li>
                <img src={account2Image} alt="account2" />
                <p>Frida</p>
              </li>
              <li>
                <img src={callImage} alt="call" />
                <p>+1 5623 895 XXX</p>
              </li>
              <li>
                <img src={messageImage} alt="message" />
                <p>peter123@gmail.com</p>
              </li>
              <li>
                <img src={locationPinImage} alt="message" />
                <p>42 Broadway, New York, NY 10004, USA</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="furnit_hist_right">
          <div className="furnit_hist_plan_cont">
            <img src={basiccleanhistimgImage} alt="furnit" />
            <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
              <h4>Basic Cleaning Plan</h4>
              <h4>Date : 1 Feb 2022 to 26 Oct 2022</h4>
              <h4>Cleaning Type : Weekly</h4>
              <h2>View Delux Furniture Items</h2>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0" />
    </div>
  );
};

export default CleaningHistory;
