import { Fragment, useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Modal } from "react-bootstrap";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import axios from "axios";
import Button from "./common/LoadingButton";
import DarkEyeImg from "../assets/images/darkeye.png";
import EyeSvg from "../assets/images/eye.svg";

const LoginSecurity = () => {
  const { user, isLoggedIn } = useUserState();
  const dispatch = useUserDispatch();
  const [inputData, setInputData] = useState({
    currentPwd: "",
    newPwd: "",
    confirmPwd: "",
    currentEmail: "",
    newEmail: "",
    confirmEmail: "",
  });
  const [inputErrorPassword, setInputErrorPassword] = useState(false);
  const [inputErrorEmail, setInputErrorEmail] = useState(false);

  const [currentEye, setCurrentEye] = useState(false);
  const [newEye, setNewEye] = useState(false);
  const [confirmEye, setConfirmEye] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isButtonLoadingEmail, setIsButtonLoadingEmail] = useState(false);
  const [isButtonLoadingEmailOtp, setIsButtonLoadingEmailOtp] = useState(false);

  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpModal, setEmailOtpModal] = useState(false);
  const [emailOtpError, setEmailOtpError] = useState(false);
  const [otpResError, setOtpResError] = useState("");

  const [changePwdError, setChangePwdError] = useState("");
  const [changePwdSuccess, setChangePwdSuccess] = useState("");

  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const pass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

  const handleKeyPhone = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };
  useEffect(() => {}, [user?.token]);

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: user?.token,
  };
  const handleSubmitPassword = async () => {
    setCurrentEye(false);
    setNewEye(false);
    setConfirmEye(false);
    setChangePwdSuccess("");
    setChangePwdError("");
    const { currentPwd, newPwd, confirmPwd } = inputData;
    if (
      !currentPwd ||
      !newPwd ||
      !confirmPwd ||
      !pass.test(newPwd) ||
      newPwd === currentPwd ||
      newPwd !== confirmPwd
    ) {
      setInputErrorPassword(true);
      return;
    } else {
      setInputErrorPassword(false);
      try {
        setIsButtonLoading(true);
        const response = await axios.put(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/updateNewPassword`,
          {
            old_password: currentPwd,
            new_password: newPwd,
          },
          { headers: headerJson }
        );
        // handleLogin(response?.data?.data);
        if (response) {
          setIsButtonLoading(false);
          setChangePwdSuccess(response?.data?.message);
          setInputData({
            currentPwd: "",
            newPwd: "",
            confirmPwd: "",
          });
        }
      } catch (error) {
        setIsButtonLoading(false);
        if (error?.response?.data?.statusCode === 401) {
          setChangePwdError(error?.response?.data?.message);
        }
        console.log("login_error", error);
      }
    }
  };

  const handleSubmitEmail = async () => {
    const { newEmail, confirmEmail } = inputData;

    if (
      !newEmail ||
      !emailRegex.test(newEmail) ||
      !confirmEmail ||
      newEmail !== confirmEmail
    ) {
      setInputErrorEmail(true);
    } else {
      setInputErrorEmail(false);
      try {
        setIsButtonLoadingEmail(true);
        const response = await axios.post(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/user/genrateOtpForUpadteEmail`,
          {
            new_email: newEmail,
          },
          { headers: headerJson }
        );
        if (response) {
          let user1 = {
            ...user,
            ...response?.data?.data,
          };
          dispatch({ type: "UPDATE_USER", payload: user1 });
          setIsButtonLoadingEmail(false);
          setEmailOtpModal(true);
        }
      } catch (error) {
        setIsButtonLoadingEmail(false);
        console.log("login_error", error);
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (!emailOtp || emailOtp.length < 6) {
      setEmailOtpError(true);
    } else {
      setEmailOtpError(false);
      try {
        setIsButtonLoadingEmailOtp(true);
        const response = await axios.put(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/updateEmail`,
          {
            new_email: inputData?.newEmail,
            otp: emailOtp,
          },
          { headers: headerJson }
        );
        if (response) {
          let user1 = {
            ...user,
            email: inputData?.newEmail,
          };
          dispatch({ type: "UPDATE_USER", payload: user1 });
          setIsButtonLoadingEmailOtp(false);
          setEmailOtpModal(false);
          setOtpResError("");
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          setOtpResError("Invalid OTP");
        } else {
          setOtpResError("");
        }
        setIsButtonLoadingEmailOtp(false);
        console.log("otpError", error);
      }
    }
  };

  return (
    <Fragment>
      <div className="main-box">
        <div className="content-header">
          <h3>My Login & Security</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="add_new_img_upload change_pass">
              <div className="house_upload_head ">Change Password</div>
            </div>
            {
              changePwdSuccess && <div className="text-success">{changePwdSuccess}</div>
            }
            <div className="change_pass_field add_new_furnit_field">
              <div className="edit_inner_pet furnit_edit_pet">
                <div className="editp_pet_field_inner password-sec">
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Current Password
                    </label>
                    <input
                      type={currentEye ? "text" : "password"}
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Password"
                      value={inputData.currentPwd}
                      onKeyDown={handleKeyPhone}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          currentPwd: e.target.value,
                        })
                      }
                    />
                    <div className="darkeye_icon">
                      <img
                        src={!currentEye ? DarkEyeImg : EyeSvg}
                        alt="darkeye"
                        onClick={() => setCurrentEye(!currentEye)}
                      />
                    </div>
                    {changePwdError && (
                      <div className="error">{changePwdError}</div>
                    )}
                    {inputErrorPassword && !inputData.currentPwd && (
                      <div className="error">
                        Please enter your current password!
                      </div>
                    )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      New Password
                    </label>
                    <input
                      type={newEye ? "text" : "password"}
                      className="form-control"
                      id="exampleFormControlInput2"
                      placeholder="New Password"
                      value={inputData.newPwd}
                      onKeyDown={handleKeyPhone}
                      onChange={(e) =>
                        setInputData({ ...inputData, newPwd: e.target.value })
                      }
                    />
                    <div className="darkeye_icon">
                      <img
                        src={!newEye ? DarkEyeImg : EyeSvg}
                        alt="darkeye"
                        onClick={() => setNewEye(!newEye)}
                      />
                    </div>
                    {inputErrorPassword &&
                      (!inputData.newPwd ||
                        !pass.test(inputData.newPwd) ||
                        inputData.newPwd === inputData.currentPwd) && (
                        <div className="error">
                          {!inputData.newPwd
                            ? "Please enter your new password!"
                            : inputData.newPwd === inputData.currentPwd
                            ? "Your new password must not match with your current password!"
                            : !pass.test(inputData.newPwd)
                            ? `Enter a valid password. (Must be of atleast 8
                              characters and must contail atleast one
                              uppercase, lowercase character and a digit.)`
                            : ""}
                        </div>
                      )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput3"
                      className="form-label"
                    >
                      Confirm New Password
                    </label>
                    <input
                      type={confirmEye ? "text" : "password"}
                      className="form-control"
                      id="exampleFormControlInput3"
                      placeholder="Confirm Password"
                      value={inputData.confirmPwd}
                      onKeyDown={handleKeyPhone}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          confirmPwd: e.target.value,
                        })
                      }
                    />
                    <div className="darkeye_icon">
                      <img
                        src={!confirmEye ? DarkEyeImg : EyeSvg}
                        alt="darkeye"
                        onClick={() => setConfirmEye(!confirmEye)}
                      />
                    </div>
                    {inputErrorPassword &&
                      (!inputData.confirmPwd ||
                        inputData.newPwd !== inputData.confirmPwd) && (
                        <div className="error">
                          {!inputData.confirmPwd
                            ? "Please confirm your password!"
                            : "Your password and confirmation password do not match"}
                        </div>
                      )}
                  </div>
                  <div className="druyp_login_btn">
                    {/* <p className="alert-sec">{"eeeee"}</p> */}
                    <Button
                      isLoading={isButtonLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmitPassword({});
                      }}
                    >
                      Change Password
                    </Button>
                  </div>
                </div>
                {/* <hr /> */}
                {/* <div className="house_upload_head update_email ">
                  Update Email
                </div> */}
                {/* <div className="editp_pet_field_inner">
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput5"
                      className="form-label"
                    >
                      New Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput5"
                      placeholder="New Email"
                      value={inputData.newEmail}
                      onKeyDown={handleKeyPhone}
                      onChange={(e) =>
                        setInputData({ ...inputData, newEmail: e.target.value })
                      }
                    />
                    {inputErrorEmail &&
                      (!inputData.newEmail ||
                        !emailRegex.test(inputData.newEmail) ||
                        inputData.newEmail === inputData.currentEmail) && (
                        <div className="error">
                          {!inputData.newEmail
                            ? "Please enter your new email!"
                            : !emailRegex.test(inputData.newEmail)
                            ? "Invalid email address"
                            : inputData.newEmail === inputData.currentEmail
                            ? "Your new email does not match with your current email"
                            : ""}
                        </div>
                      )}
                  </div>
                  <div className="profile_field">
                    <label
                      htmlFor="exampleFormControlInput6"
                      className="form-label"
                    >
                      Confirm New Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput6"
                      placeholder="Confirm Email"
                      value={inputData.confirmEmail}
                      onKeyDown={handleKeyPhone}
                      onChange={(e) =>
                        setInputData({
                          ...inputData,
                          confirmEmail: e.target.value,
                        })
                      }
                    />
                    {inputErrorEmail &&
                      (!inputData.confirmEmail ||
                        inputData.newEmail !== inputData.confirmEmail) && (
                        <div className="error">
                          {!inputData.confirmEmail
                            ? "Please confirm your new email!"
                            : "Your new email and confirmation email do not match"}
                        </div>
                      )}
                  </div>
                  <div style={{ width: "50%" }} className="druyp_login_btn">
                    <Button
                      isLoading={isButtonLoadingEmail}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmitEmail({});
                      }}
                    >
                      Update Email
                    </Button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Email Otp Modal */}
      <Modal
        show={emailOtpModal}
        onHide={() => setEmailOtpModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        id="emailOtpModal"
      >
        <Modal.Header closeButton>Enter OTP</Modal.Header>

        <Modal.Body>
          <OtpInput
            value={emailOtp}
            onChange={setEmailOtp}
            numInputs={6}
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "14px",
              borderRadius: "7px",
              border: "2px solid rgba(0,0,0,0.3)",
              background: "#dddddd",
              fontSize: "20px",
            }}
            renderInput={(props) => <input {...props} />}
          />
          {emailOtpError && (!emailOtp || emailOtp.length < 6) && (
            <span>
              {!emailOtp
                ? "Please enter OTP"
                : emailOtp.length < 6
                ? "Invalid OTP"
                : ""}
            </span>
          )}
          {!emailOtpError && <span>{otpResError}</span>}
          <div style={{ width: "100%" }} className="druyp_login_btn">
            <Button
              isLoading={isButtonLoadingEmailOtp}
              onClick={(e) => {
                e.preventDefault();
                handleOtpSubmit({});
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default LoginSecurity;
