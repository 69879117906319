import Logo from "@images/logo.png";

import { Link } from "react-router-dom";
import "./VerifyEmail.css"
const VerifyEmail = () => {
  return (
    <div>
      <div className="verify_main">
        <div className="mail_logo">
          <img src={Logo} alt="logo" />
          <h4>please check your email and verify email.</h4>
          <Link to="/login">Go Back</Link>
        </div>
        
      </div>
    </div>
  );
};

export default VerifyEmail;
