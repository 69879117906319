const setSessionStorageWithExpiration = (key, value) => {
  const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 1H  60M * 60S Convert minutes to milliseconds
  const data = {
    value,
    expirationTime,
  };
  sessionStorage.setItem(key, JSON.stringify(data));
};

const getSessionStorageWithExpiration = (key) => {
  const data = sessionStorage.getItem(key);
  if (!data) {
    return null; // Data doesn't exist in sessionStorage
  }

  const parsedData = JSON.parse(data);
  const { value, expirationTime } = parsedData;
  const currentTime = new Date().getTime();

  if (currentTime > expirationTime) {
    sessionStorage.removeItem(key); // Remove the data if it has expired
    return null;
  }

  return value;
};

export { setSessionStorageWithExpiration, getSessionStorageWithExpiration };
