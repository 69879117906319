import { useEffect, useState, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserState, UserContext } from "../../../Contexts/UserContext";

import plusImage from "@images/plus.svg";
import EditImg from "../../../assets/images/Edit.png";
import TrashImg from "../../../assets/images/Trash.png";

import "./index.css";
import AddCleaningService from "../AddCleaningService";

const MyCleaningStore = () => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const { setCleanEditData } = useContext(UserContext);
  const [categoryId, setCategoryId] = useState("1");
  const [furnitureData, setFurnitureData] = useState([]);
  const [cleaningCategories, setCleaningCategories] = useState([]);

  const [showCleaningService, setShowCleaningService] = useState(false);
  const [showAddNewServiceLink, setShowAddNewServiceLink] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);


  const getFurnitures = async () => {
    setBtnLoader(true)
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    if (user?.token) {
      try {
        let res = await axios.get(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/cleaning-service/get-by-user`,
          { headers: headersFormData }
        );
        setFurnitureData(res?.data?.data);
        setBtnLoader(false)
      } catch (err) {
        setBtnLoader(false)
        return console.log(err);
      }
    }
  };


  const handleAddServiceClick = () => {
    setShowCleaningService(true);
    setShowAddNewServiceLink(false); // Hide the link when the button is clicked
  };




  const handleDeleteFurniture = async (id) => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    if (user?.token) {
      try {
        let res = await axios.delete(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/cleaning-service/delete/${id}`,
          { headers: headersFormData }
        );
        getFurnitures();
      } catch (err) {
        return console.log(err);
      }
    }
  };

  const getCleaningCategories = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    try {
      const res = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/cleaning-service-category/get-list`,
        { headers: headersFormData }
      );
      if (res) {
        setCleaningCategories(res?.data?.data);
      }
    } catch (error) {
      console.log("Error while fetching cleaning categories", error?.message);
    }
  };

  useEffect(() => {
    getCleaningCategories();
  }, [user?.token]);

  useEffect(() => {
    getFurnitures();
    // eslint-disable-next-line
  }, [user?.token, categoryId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main-box">
      <div className="content-header">  <h3>My Cleaning Store</h3></div>
      <div className="myfurnit_store_main">
       
        {/* <Link
          to="/user_dash/cleaning_company/add-cleaning-service"
          onClick={() => setCleanEditData("")}
        >
          <img src={plusImage} alt="Edit" /> Add New Service
        </Link> */}

<div className="text-center">
      {(furnitureData && furnitureData.length===0 ? showCleaningService : !showCleaningService) && <AddCleaningService  furnitureData={furnitureData}/>}
      {!btnLoader && furnitureData && furnitureData.length===0 && showAddNewServiceLink && (
        <button className="druyp-btn plan-action" onClick={handleAddServiceClick}>
          Add New Service
        </button>
      )}{btnLoader && (
        <div class="eye-loader-frame m-5 p-5">
          <div class="eye-loader-spinner"></div>
        </div>
      )}
    </div>


        {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
          {
            cleaningCategories && cleaningCategories?.map((ele) => {
              const isActive = ele.id === categoryId;
              return (
                <li className="nav-item" role="presentation" key={ele?.id}>
            <button
              className={`nav-link ${isActive ? "active" : ""}`}
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              onClick={() => setCategoryId(ele?.id)}
            >
              {ele?.name}
            </button>
          </li>
              )
            })
          }
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            role="tabpanel"
          >
            <div className="clean_store_cont cleaning-store-innew">
              <ul>
                {furnitureData?.length > 0 ? (
                  furnitureData?.map((ele) => {
                    return (
                      <li className="mystore_cont_inner" key={ele?.id}>
                        <div className="my_store_img">
                          <img
                            src={`${import.meta.env.VITE_APP_BASE_URL}${
                              ele?.cleaning_servive_images[0]?.imageUrl
                            }`}
                            alt="storebed"
                          />
                          <div
                            className="delux_edit_icon"
                            onClick={() => {
                              setCleanEditData(ele);
                              navigate(
                                "/user_dash/cleaning_company/add-cleaning-service"
                              );
                            }}
                          >
                            <img src={EditImg} alt="edit" />
                          </div>
                          <div
                            className="trash_icon"
                            onClick={() => handleDeleteFurniture(ele?.id)}
                          >
                            <img src={TrashImg} alt="trash" />
                          </div>
                        </div>
                        <h4>${ele?.price}/month</h4>
                        <p>{ele?.description}</p>
                      </li>
                    );
                  })
                ) : (
                  <p>There is currently no item</p>
                )}
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MyCleaningStore;
