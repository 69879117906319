import { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import axios from "axios";
import { Spinner } from "react-bootstrap";
const defaultResendEmailData = {
  message: "",
  isEmailSent: "",
};
const Otp_Screen = () => {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const { fUser } = location.state;
  const navigate = useNavigate();
  const [checkOtp, setCheckOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendEmailRes, setResendEmailRes] = useState(defaultResendEmailData);
  const [loader, setLodaer] = useState(false);
  const headers = {
    "Content-Type": "application/json",
  };
  const varifyOtp = async () => {
    if (otp) {
      setLodaer(true);
      setCheckOtp(false);
      setResendEmailRes(defaultResendEmailData);

      setErrorMessage("");
      let data = {
        email: fUser,
        otp: otp,
      };
      try {
        let res = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/verifyOtp`,
          data,
          { headers: headers }
        );
        if (res?.data?.message === "OTP verified successfully") {
          navigate("/change_Password", { state: { fUser: fUser } });
        }
      } catch (err) {
        if (err?.response?.data) {
          setErrorMessage(err?.response?.data?.message);
        }
        console.log(err);
      }
      setLodaer(false);
    } else {
      setCheckOtp(true);
    }
  };
  const sendOtp = async () => {
    try {
      if (fUser) {
        let data = {
          email: fUser,
        };
        let res = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/user/resetPwd`,
          data,
          { headers: headers }
        );
        if (res) {
          setResendEmailRes({
            message: "OTP successfully sent to your email.",
            isEmailSent: true,
          });
          // navigate("/otp",{state:{fUser:email}});
        }
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setResendEmailRes({
        message:
          "Failed to send OTP to your email. Please try again after some time!",
        isEmailSent: false,
      });
      // Handle error here if needed
    }
  };

  return (
    <div className="otp_screen">
      <section className="druyp_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center min-vh-100">
            <div className="col-lg-12">
              <div className="druyp_login_inner otp_screen_inner">
                <div className="druyp_logo ">Druyp </div>
                <h4>Enter Access Code</h4>
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Enter the code given to you sent by email
                        </label>
                        <div className="d-flex justify-content-between">
                          <div className="otp-sec-input">
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              inputStyle={{
                                width: "5rem",
                                height: "4rem",
                                margin: "14px",
                                borderRadius: "7px",
                                border: "2px solid rgba(0,0,0,0.3)",
                                background: "#dddddd",
                                fontSize: "20px",
                              }}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                        {errorMessage ? (
                          <p className="otpMessage">{errorMessage}</p>
                        ) : (
                          ""
                        )}
                        {checkOtp && (
                          <div className="error">Please enter Otp .</div>
                        )}
                      </div>
                      <div className="druyp_login_btn">
                        <button
                          onClick={varifyOtp}
                          type="submit"
                          disabled={loader}
                        >
                          VERIFY NOW
                          {loader ? <Spinner></Spinner> : null}
                        </button>
                      </div>

                      <div className="create_new rotp">
                        Don't received the OTP?{" "}
                        <a onClick={sendOtp}>Resend Access Code</a>
                        {resendEmailRes?.message && (
                          <p
                            className={
                              resendEmailRes.isEmailSent
                                ? "otpSentSuccess"
                                : "error"
                            }
                          >
                            {resendEmailRes?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Otp_Screen;
