import { Fragment, useEffect, useState, useContext } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Modal, Spinner, Button, ButtonGroup } from "react-bootstrap";
import axios from "axios";
import { useUserState, UserContext } from "../../../Contexts/UserContext";

import PlusSvg from "../../../assets/images/plus.svg";
import EditImg from "../../../assets/images/Edit.png";
import TrashImg from "../../../assets/images/Trash.png";

const MyFurnitureStore = () => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const { setFurEditData } = useContext(UserContext);
  const [categoryId, setCategoryId] = useState("1");
  const [furnitureData, setFurnitureData] = useState([]);
  const [furPlan, setFurPlan] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [delFurId, setDelFurId] = useState("");
  const [delLoader, setDelLoader] = useState(false);

  const getFurnitures = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    if (user?.token) {
      try {
        let res = await axios.get(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/furniture/get-by-user/${categoryId}`,
          { headers: headersFormData }
        );
        setFurnitureData(res?.data?.data);
      } catch (err) {
        return console.log(err);
      }
    }
  };

  const handleDeleteFurniture = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    if (user?.token) {
      setDelLoader(true);
      try {
        let res = await axios.delete(
          `${
            import.meta.env.VITE_APP_BASE_URL
          }/api/furniture/delete/${delFurId}`,
          { headers: headersFormData }
        );
        if (res) {
          setDelLoader(false);
          getFurnitures();
          setDeleteModal(false);
        }
      } catch (err) {
        console.log(err);
        setDelLoader(false);
      }
    }
  };

  const getCategoriesData = async () => {
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    try {
      let res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_URL}/api/furniture-category/get-list`,
        { headers: headersFormData }
      );
      setFurPlan(res?.data?.data);
    } catch (err) {
      return console.log("get_category", err);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, [user?.token]);

  useEffect(() => {
    getFurnitures();
    // eslint-disable-next-line
  }, [user?.token, categoryId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    // eslint-disable-next-line
  }, []);
  const handleNewAddFurniture=(id)=>{
    setFurEditData("")
    navigate("/user_dash/furniture_company/add_new_furniture", {
      state: { categoryId: id },
    });
  }

  return (
    <Fragment>
      <div className="main-box">
        <div className="content-header">
          <h3>My Furniture Store</h3>
          <button
            type="button"
            className="druyp-btn"
            onClick={() => handleNewAddFurniture(categoryId)}
          >
            <img src={PlusSvg} alt="Edit" /> Add New Furniture
          </button>
        </div>
        <div className="myfurnit_store_main">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {furPlan &&
              furPlan?.map((ele) => {
                const isActive = ele.id === categoryId;
                return (
                  <li className="nav-item" role="presentation" key={ele?.id}>
                    <button
                      className={`nav-link ${isActive ? "active" : ""}`}
                      type="button"
                      onClick={() => setCategoryId(ele?.id)}
                    >
                      {ele?.name}
                    </button>
                  </li>
                );
              })}
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" role="tabpanel">
              <ul className="mystore_cont">
                {furnitureData?.length > 0 ? (
                  furnitureData?.map((ele) => {
                    return (
                      <li className="mystore_cont_inner" key={ele?.id}>
                        <div className="my_store_img">
                          <img
                            src={`${import.meta.env.VITE_APP_BASE_URL}${
                              ele?.furniture_images[0]?.imageUrl
                            }`}
                            alt="furniture"
                          />
                          <div
                            className="delux_edit_icon"
                            onClick={() => {
                              setFurEditData(ele);
                              navigate(
                                "/user_dash/furniture_company/add_new_furniture"
                              );
                            }}
                          >
                            <img src={EditImg} alt="edit" />
                          </div>
                          <div
                            className="trash_icon"
                            onClick={() => {
                              setDelFurId(ele?.id);
                              setDeleteModal(true);
                            }}
                          >
                            <img src={TrashImg} alt="trash" />
                          </div>
                        </div>
                        <h4>{ele?.name}</h4>
                      </li>
                    );
                  })
                ) : (
                  <p>There is currently no item</p>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Furniture</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this furniture!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal(false)}>
            No
          </Button>
          <Button
            variant="primary"
            disabled={delLoader}
            onClick={() => handleDeleteFurniture()}
          >
            Yes {delLoader && <Spinner />}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default MyFurnitureStore;
