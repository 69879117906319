import axios from "axios";

import PremiumCleaningImg from "@images/premium-cleaning.png";
import DeluxCleaningImg from "@images/delux-cleaning.png";
import CustomModal from "../../../common/CustomModal";
import ChooseOtherPlanModal from "./ChooseOtherPlanModal";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import { differenceInDays, parse } from "date-fns";
import { Button, Spinner } from "react-bootstrap";
import { useUserState } from "../../../../Contexts/UserContext";

function CleaningOtherPlans({ serviceCompanyId, companyServices }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { packageArr } = location.state || {};
  const [selectedCleaningCompany] = useState(location.state);
  const { user, isLoggedIn } = useUserState();
  const [showPremium, setShowPremium] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [showDelux, setShowDelux] = useState(false);
  const [companyData, setComapnyData] = useState([]);
  const [cleaningPackage, setCleaningPackage] = useState(1);
  const [addCleaningPack, setAddCleaningPack] = useState();
  const [selectCleaningPack, setSelectCleaningPack] = useState();
  const [formattedFromDate, setFormattedFromDate] = useState();
  const [formattedToDate, setFormattedToDate] = useState();
  const [basicPackPrice, setBasicPackPrice] = useState(0);
  const [primiumPackPrice, setPrimiumPackPrice] = useState(0);
  const [deluxPackPrice, setDeluxPackPrice] = useState(0);
  const [totalPackagePrice, setTotalPackagePrice] = useState(0);
  const [isPremiumSelected, setIsPremiumSelected] = useState();
  const [isBasicSelected, setIsBasicSelected] = useState();
  const [isDeluxSelected, setIsDeluxSelected] = useState();
  const fromDate = packageArr?.startDate;
  const toDate = packageArr?.endDate;
  const [cart, setCart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedCleaningPackArray, setSelectedCleaningPackArray] = useState(
    []
  );

  const headers = {
    Authorization: user?.token,
  };


  useEffect(() => {
    if (companyServices?.length > 0) {
      // let newcart = cart;
      // setCart([])
      setDeluxPackPrice(companyServices[2].price);
      setPrimiumPackPrice(companyServices[1].price);
      setBasicPackPrice(companyServices[0].price);

      //setCart(newCart)
    }
  }, [serviceCompanyId]);

  useEffect(() => {
    cleaningPackageList();
  }, [deluxPackPrice]);
  useEffect(() => {
    fetchComapnyData();
    cleaningPackageList();
    let updatedPackage = packageArr;

    if (packageArr?.package_name)
      setCart((prev) => [...prev, { ...packageArr }]);
    const fromDateVal = new Date(fromDate);
    const toDateVal = new Date(toDate);

    const formattedFromDateVal = fromDateVal.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    setFormattedFromDate(formattedFromDateVal);
    const formattedToDateVal = toDateVal.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    setFormattedToDate(formattedToDateVal);
    // const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    //setCart(storedCart);
  }, [user?.token]);

  const cleaningPackageList = async () => {
    // debugger
    const fromDate =  packageArr?.startDate;
    const toDate =  packageArr?.endDate;
    let fromDateVal;
    let toDateVal;
    fromDateVal = new Date(fromDate);
    toDateVal = new Date(toDate);
    if (fromDateVal > toDateVal) {
      [fromDateVal, toDateVal] = [toDateVal, fromDateVal];
    }
    const timeDifference = toDateVal.getTime() - fromDateVal.getTime();
    const daysDifference =
      Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
    const updatedCart = [...cart];
    let deluxQty = Math.floor(daysDifference / 30);
    const remainingDays = daysDifference % 30;
    const isDeluxInCart = updatedCart.some(
      (item) => item.package_name === "Delux"
    );
    if (deluxQty > 0 && !isDeluxInCart) {
      if (remainingDays > 15) {
        deluxQty = deluxQty + 1;
      }
      const newItemDelux = {
        package_name: "Delux",
        price: deluxPackPrice,
        qty: deluxQty.toString(),
        status: "0",
      };
      updatedCart.push(newItemDelux);
    }
    if (remainingDays > 0) {
      if (remainingDays <= 10) {
        setAddCleaningPack("Basic");
        const newItemBasic = {
          package_name: "Basic",
          price: basicPackPrice,
          qty: "1",
          status: "0",
        };
        if (!updatedCart.some((item) => item.package_name === "Basic")) {
          updatedCart.push(newItemBasic);
        }
      } else if (remainingDays <= 15) {
        setAddCleaningPack("Premium");
        const newItemPremium = {
          package_name: "Premium",
          price: primiumPackPrice,
          qty: "1",
          status: "0",
        };
        if (!updatedCart.some((item) => item.package_name === "Premium")) {
          updatedCart.push(newItemPremium);
        }
      }
    }
    // debugger
    let newUpdatedCart = updatedCart.map((item) => {
      if (item.package_name === "Basic" || item.planId == 3) {
        item.price = basicPackPrice;
      } else if (item.package_name === "Premium" || item.planId == 2) {
        item.price = primiumPackPrice;
      } else if (item.package_name === "Delux" || item.planId == 1) {
        item.price = deluxPackPrice;
      }
      return item;
    });
    const totalPrice = newUpdatedCart.reduce(
      (total, item) => total + parseFloat(item.price) * parseInt(item.qty),
      0
    );
    setTotalPackagePrice(totalPrice);
    setCart(newUpdatedCart);
  };

  const fetchComapnyData = async () => {
    const apiUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/user/getAllCompany/cleaning_company`;
    const res = await axios.get(apiUrl, {
      headers: headers,
    });

    if (res.data) {
      setComapnyData(res.data.data);
    }
  };

  const handleCleaningTab = (number) => {
    setCleaningPackage(number);
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const updatePackageQty = (name, qtystatus) => {
    setCart((prevCart) => {
      let updatedCart = prevCart.map((item) => {
        if (
          item.package_name === name &&
          qtystatus === "increase" &&
          item.status === "1" &&
          parseInt(item.qty) > 0
        ) {
          // Increase the quantity
          const newQty = parseInt(item.qty) + 1;
          return { ...item, qty: newQty.toString() };
        } else if (
          item.package_name === name &&
          qtystatus === "decrease" &&
          item.status === "1" &&
          parseInt(item.qty) > 1
        ) {
          // Decrease the quantity, but ensure it doesn't go below 1
          const newQty = parseInt(item.qty) - 1;
          return { ...item, qty: newQty.toString() };
        }
        return item;
      });
      updatedCart = updatedCart.map((item) => {
        if (item.package_name === "Basic") {
          item.price = basicPackPrice;
        } else if (item.package_name === "Premium") {
          item.price = primiumPackPrice;
        } else if (item.package_name === "Delux") {
          item.price = deluxPackPrice;
        }
        return item;
      });
      // Calculate total price using the updated cart
      const totalPrice = updatedCart.reduce(
        (total, item) => total + parseFloat(item.price) * parseInt(item.qty),
        0
      );

      // Update setTotalPackagePrice with the new total price
      setTotalPackagePrice(totalPrice);

      // Update boolean flags based on the updated cart
      const isPremiumSelect = updatedCart.some(
        (item) => item.package_name === "Premium" && item.status === "1"
      );
      setIsPremiumSelected(isPremiumSelect);

      const isBasicSelect = updatedCart.some(
        (item) => item.package_name === "Basic" && item.status === "1"
      );
      setIsBasicSelected(isBasicSelect);

      const isDeluxSelect = updatedCart.some(
        (item) => item.package_name === "Delux" && item.status === "1"
      );
      setIsDeluxSelected(isDeluxSelect);

      return updatedCart;
    });
  };

  const removePackage = (name) => {
    setCart((prevCart) => {
      // Use filter to exclude the item with the specified name and status from the cart
      const updatedCart = prevCart.filter(
        (item) => !(item.package_name === name && item.status === "1")
      );

      // Calculate total price using the updated cart
      const totalPrice = updatedCart.reduce(
        (total, item) => total + parseFloat(item.price) * parseInt(item.qty),
        0
      );

      // Update setTotalPackagePrice with the new total price
      setTotalPackagePrice(totalPrice);

      // Update boolean flags based on the updated cart
      const isPremiumSelect = updatedCart.some(
        (item) => item.package_name === "Premium" && item.status === "1"
      );
      setIsPremiumSelected(isPremiumSelect);

      const isBasicSelect = updatedCart.some(
        (item) => item.package_name === "Basic" && item.status === "1"
      );
      setIsBasicSelected(isBasicSelect);

      const isDeluxSelect = updatedCart.some(
        (item) => item.package_name === "Delux" && item.status === "1"
      );
      setIsDeluxSelected(isDeluxSelect);

      return updatedCart;
    });
  };

  const selectedCleaningPackage = (name, price) => {
    const packageArr = {
      package_name: name,
      price: price,
      qty: "1",
      status: "1",
    };

    const selectedCart = [...selectedCleaningPackArray];
    selectedCart.push(packageArr);

    setCart((prevCart) => {
      const newCart = [...prevCart, packageArr];

      const totalPrice = newCart.reduce(
        (total, item) => total + parseFloat(item.price) * parseInt(item.qty),
        0
      );

      setTotalPackagePrice(totalPrice);

      const isPremiumSelect = newCart.some(
        (item) => item.package_name === "Premium" && item.status === "1"
      );
      setIsPremiumSelected(isPremiumSelect);

      const isBasicSelect = newCart.some(
        (item) => item.package_name === "Basic" && item.status === "1"
      );
      setIsBasicSelected(isBasicSelect);

      const isDeluxSelect = newCart.some(
        (item) => item.package_name === "Delux" && item.status === "1"
      );
      setIsDeluxSelected(isDeluxSelect);

      return newCart;
    });
  };

  const customStyle = {
    header: {
      border: "none",
    },
    footer: {
      border: "none",
    },
    modalButton: {
      borderRadius: "45px",
      background: "#0474BA",
      color: "#fff !important",
      padding: "12px",
      display: "block",
      margin: "10px 0 0",
      textAlign: "center",
      fontWeight: "700",
      width: "100%",
    },
    addedSecs: {
      background: "#70BA04",
    },
  };

  const packageIdMapping = {
    Basic: 3,
    Premium: 2,
    Delux: 1,
    // Add more mappings as needed
  };

  const handleProceedButtonCleaning = async () => {
    const baseCartUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/cart/add-to-cart`;

    const packageIdMapping = {
      Basic: 3,
      Premium: 2,
      Delux: 1,
      // Add more mappings as needed
    };
    let newCart = [];

    for (const cartData of cart) {
      const { package_name, qty } = cartData;

      const id = packageIdMapping[package_name] || cartData.id;

      try {
        setIsButtonLoading(true);
        await axios.post(
          baseCartUrl,
          {
            cleaning_service_category_id: id,
            house_id: localStorage.getItem("houseId"),
            service_owner: serviceCompanyId,
            frequency: qty,
            dataType: "cleaning",
          },
          { headers }
        );
        setIsButtonLoading(false);
      } catch (error) {
        // Handle error if needed
        console.error("Error adding to cart:", error);
        setIsButtonLoading(false);
      }

      newCart.push({ ...cartData, id });
    }

    // let newCart = cart.map(async (cartData) => {
    //   const { package_name, qty } = cartData;

    //   const id = packageIdMapping[package_name] || cartData.id;

    //   try {
    //     await axios.post(
    //       baseCartUrl,
    //       {
    //         cleaning_service_category_id: id,
    //         house_id: localStorage.getItem("houseId"),
    //         service_owner: "133",
    //         frequency: qty,
    //         dataType: "cleaning",
    //       },
    //       { headers }
    //     );
    //   } catch (error) {
    //     // Handle error if needed
    //     console.error("Error adding to cart:", error);
    //   }

    //   return { ...cartData, id };
    // });
    navigate("/cart");
  };

  const handleProceed = async () => {
    const finalArray = [];
    if (cart?.length > 0) {
      for (let i = 0; i < cart?.length; i++) {
        const element = cart[i];
        const newObj = {
          cleaning_service_category_id:
            packageIdMapping[element?.package_name?.split(" ")[0]],
          house_id: packageArr?.houseId,
          service_owner: serviceCompanyId,
          frequency: element?.qty,
          is_mandatory: element?.status === "0" ? 1 : 0,
          price: element?.price,
          cleaning_service_id: companyServices.find(packageData=> packageData.cleaning_service_category_id == packageIdMapping[element?.package_name?.split(" ")[0]] ).id,
        };
        finalArray.push(newObj);
      }
    }
    let payload = {
      furData: finalArray,
      dataType: "cleaning",
    };

    const apiUrl = `${import.meta.env.VITE_APP_BASE_URL}/api/cart/update-cart/${
      packageArr?.cartId
    }`;

    setLoader(true);
    try {
      const res = await axios.put(apiUrl, payload, {
        headers: headers,
      });
      setLoader(false);
      if (res?.data?.statusCode === 200) {
        navigate(
          `/cart?cart=${packageArr?.cartId}&&house=${packageArr?.houseId}`,  {
            state: {
              startDate: location?.state?.packageArr?.startDate,
              endDate: location?.state?.packageArr?.endDate,
            },
          }
        );
      }
    } catch (error) {
      setLoader(false);
      console.log("Error while hitting update cart API", error);
    }
  };

  return (
    <>
      <div className="cleaning-package-details basic-new-plan">
        <div className="container-fluid my-container">
          {packageArr?.package_name && (
            <div class="row">
              <div class="col-md-12">
                <h3>What we do in {packageArr?.package_name}</h3>
                <p>
                  {companyServices.length
                    ? companyServices.find(
                        (d) =>
                          d.cleaning_service_category_id == packageArr?.planId
                      )?.description
                    : ""}
                  .
                </p>
              </div>
            </div>
          )}
          {/* <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="search-header">
                <h2>Choose Cleaning Package</h2>
                <p>
                  <b>Time duration : </b> {formattedFromDate} -{" "}
                  {formattedToDate}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_opt_cont">
                <ul>
                  <li>
                    <h4>Basic</h4>
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Dusting
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Kitchen Cleaning
                  </li>
                </ul>
                <div className="control_num added-sec">
                  {isBasicSelected ? (
                    <>
                      <a style={{ background: "#70BA04" }}>Added</a>
                      <span>${numberWithCommas(basicPackPrice)}</span>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={(event) =>
                          selectedCleaningPackage("Basic", "1000")
                        }
                      >
                        Add plan to cart
                      </a>
                      <span>${numberWithCommas(basicPackPrice)}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_opt_cont recomended">
                <span>Recommended Plan</span>
                <ul>
                  <li>
                    <h4>Premium</h4>
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Full House Cleaning
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Kitchen Dry + Wet
                    Cleaning
                  </li>
                </ul>
                <div className="control_num added-sec">
                  {isPremiumSelected ? (
                    <>
                      <a style={{ background: "#70BA04" }}>Added</a>
                      <span>${numberWithCommas(primiumPackPrice)}</span>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={(event) =>
                          selectedCleaningPackage("Premium", "2000")
                        }
                      >
                        Add plan to cart
                      </a>
                      <span>${numberWithCommas(primiumPackPrice)}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_opt_cont">
                <ul>
                  <li>
                    <h4>Delux</h4>
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Dusting
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Full House Cleaning
                  </li>
                  <li>
                    <i className="fa fa-solid fa-check"></i> Kitchen Dry + Wet
                    Cleaning
                  </li>
                </ul>
                <div className="control_num added-sec">
                  {isDeluxSelected ? (
                    <>
                      <a style={{ background: "#70BA04" }}>Added</a>
                      <span>${numberWithCommas(deluxPackPrice)}</span>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={(event) =>
                          selectedCleaningPackage("Delux", "3000")
                        }
                      >
                        Add plan to cart
                      </a>
                      <span>${numberWithCommas(deluxPackPrice)}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="search-header bottom">
                <p>
                  <i className="fa fa-info" aria-hidden="true"></i> Adding one
                  or more cleaning package is available for your cart
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="select_pack">
        <section className="pack_sec">
          <div className="container-fluid my-container">
            <div className="row">
              <div className="col-12">
                <div className="pack_conts">
                  <div className="sel_pac">
                    <div className="select-content">
                      {/*<h4>Select Package</h4>
                      <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M9.64157 8.35714C9.64157 8.18665 9.57384 8.02313 9.45328 7.90257C9.33272 7.78202 9.16921 7.71429 8.99871 7.71429C8.82822 7.71429 8.66471 7.78202 8.54415 7.90257C8.42359 8.02313 8.35586 8.18665 8.35586 8.35714V12.2143C8.35586 12.3848 8.42359 12.5483 8.54415 12.6689C8.66471 12.7894 8.82822 12.8571 8.99871 12.8571C9.16921 12.8571 9.33272 12.7894 9.45328 12.6689C9.57384 12.5483 9.64157 12.3848 9.64157 12.2143V8.35714ZM9.963 5.78571C9.963 6.04129 9.86147 6.28639 9.68075 6.46711C9.50004 6.64783 9.25493 6.74936 8.99936 6.74936C8.74378 6.74936 8.49868 6.64783 8.31796 6.46711C8.13724 6.28639 8.03571 6.04129 8.03571 5.78571C8.03571 5.53031 8.13717 5.28537 8.31777 5.10477C8.49837 4.92417 8.74331 4.82271 8.99871 4.82271C9.25412 4.82271 9.49906 4.92417 9.67966 5.10477C9.86026 5.28537 9.963 5.53031 9.963 5.78571ZM9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0ZM1.28571 9C1.28571 7.98695 1.48525 6.98381 1.87293 6.04787C2.26061 5.11193 2.82884 4.26151 3.54518 3.54518C4.26151 2.82884 5.11193 2.26061 6.04787 1.87293C6.98381 1.48525 7.98695 1.28571 9 1.28571C10.0131 1.28571 11.0162 1.48525 11.9521 1.87293C12.8881 2.26061 13.7385 2.82884 14.4548 3.54518C15.1712 4.26151 15.7394 5.11193 16.1271 6.04787C16.5147 6.98381 16.7143 7.98695 16.7143 9C16.7143 11.046 15.9015 13.0081 14.4548 14.4548C13.0081 15.9015 11.046 16.7143 9 16.7143C6.95404 16.7143 4.99189 15.9015 3.54518 14.4548C2.09847 13.0081 1.28571 11.046 1.28571 9Z" fill="#545454"/>
                        </svg> 
                        Choose package to use frequency option
                      </p>*/}
                    </div>
                    <h4>
                      Total -{" "}
                      <span>${numberWithCommas(totalPackagePrice)}</span>
                    </h4>
                  </div>
                </div>

                {selectedCleaningPackArray && (
                  <div className="pack_cont">
                    <div className="prem_sel">
                      {cart[cart?.length - 1]?.status === "1" && (
                        <div className="sel_pac bottom">
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M9.64157 8.35714C9.64157 8.18665 9.57384 8.02313 9.45328 7.90257C9.33272 7.78202 9.16921 7.71429 8.99871 7.71429C8.82822 7.71429 8.66471 7.78202 8.54415 7.90257C8.42359 8.02313 8.35586 8.18665 8.35586 8.35714V12.2143C8.35586 12.3848 8.42359 12.5483 8.54415 12.6689C8.66471 12.7894 8.82822 12.8571 8.99871 12.8571C9.16921 12.8571 9.33272 12.7894 9.45328 12.6689C9.57384 12.5483 9.64157 12.3848 9.64157 12.2143V8.35714ZM9.963 5.78571C9.963 6.04129 9.86147 6.28639 9.68075 6.46711C9.50004 6.64783 9.25493 6.74936 8.99936 6.74936C8.74378 6.74936 8.49868 6.64783 8.31796 6.46711C8.13724 6.28639 8.03571 6.04129 8.03571 5.78571C8.03571 5.53031 8.13717 5.28537 8.31777 5.10477C8.49837 4.92417 8.74331 4.82271 8.99871 4.82271C9.25412 4.82271 9.49906 4.92417 9.67966 5.10477C9.86026 5.28537 9.963 5.53031 9.963 5.78571ZM9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0ZM1.28571 9C1.28571 7.98695 1.48525 6.98381 1.87293 6.04787C2.26061 5.11193 2.82884 4.26151 3.54518 3.54518C4.26151 2.82884 5.11193 2.26061 6.04787 1.87293C6.98381 1.48525 7.98695 1.28571 9 1.28571C10.0131 1.28571 11.0162 1.48525 11.9521 1.87293C12.8881 2.26061 13.7385 2.82884 14.4548 3.54518C15.1712 4.26151 15.7394 5.11193 16.1271 6.04787C16.5147 6.98381 16.7143 7.98695 16.7143 9C16.7143 11.046 15.9015 13.0081 14.4548 14.4548C13.0081 15.9015 11.046 16.7143 9 16.7143C6.95404 16.7143 4.99189 15.9015 3.54518 14.4548C2.09847 13.0081 1.28571 11.046 1.28571 9Z"
                                fill="#545454"
                              />
                            </svg>
                             Cleaning packages
                          </p>
                        </div>
                      )}

                      {cart.length > 0 &&
                        cart.map(
                          (selectedCleaning, index) =>
                            selectedCleaning.status === "1" && (
                              <div key={index} className="cleaning-plan-sec">
                                <ul>
                                  <li>
                                    <div className="plan-n-p">
                                      <a
                                        onClick={(event) =>
                                          removePackage(
                                            selectedCleaning.package_name
                                          )
                                        }
                                      >
                                        <i className="fa fa-solid fa-xmark"></i>
                                      </a>
                                      <p>
                                        {selectedCleaning.package_name} {" "}
                                        Plan{" "}
                                        <span className="price">
                                          $
                                          {numberWithCommas(
                                            selectedCleaning.price
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="plan-frequency">
                                      <p>Select Frequency</p>
                                      <div className="add-item">
                                        <a
                                          onClick={(event) =>
                                            updatePackageQty(
                                              selectedCleaning.package_name,
                                              "decrease"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa fa-minus"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <p>{selectedCleaning.qty}</p>
                                        <a
                                          onClick={(event) =>
                                            updatePackageQty(
                                              selectedCleaning.package_name,
                                              "increase"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa fa-plus"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )
                        )}
                    </div>
                  </div>
                )}
                <div className="pack_conts">
                  <div className="prem_sel">
                    {/* <div className="sel_pac bottom">
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M9.64157 8.35714C9.64157 8.18665 9.57384 8.02313 9.45328 7.90257C9.33272 7.78202 9.16921 7.71429 8.99871 7.71429C8.82822 7.71429 8.66471 7.78202 8.54415 7.90257C8.42359 8.02313 8.35586 8.18665 8.35586 8.35714V12.2143C8.35586 12.3848 8.42359 12.5483 8.54415 12.6689C8.66471 12.7894 8.82822 12.8571 8.99871 12.8571C9.16921 12.8571 9.33272 12.7894 9.45328 12.6689C9.57384 12.5483 9.64157 12.3848 9.64157 12.2143V8.35714ZM9.963 5.78571C9.963 6.04129 9.86147 6.28639 9.68075 6.46711C9.50004 6.64783 9.25493 6.74936 8.99936 6.74936C8.74378 6.74936 8.49868 6.64783 8.31796 6.46711C8.13724 6.28639 8.03571 6.04129 8.03571 5.78571C8.03571 5.53031 8.13717 5.28537 8.31777 5.10477C8.49837 4.92417 8.74331 4.82271 8.99871 4.82271C9.25412 4.82271 9.49906 4.92417 9.67966 5.10477C9.86026 5.28537 9.963 5.53031 9.963 5.78571ZM9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0ZM1.28571 9C1.28571 7.98695 1.48525 6.98381 1.87293 6.04787C2.26061 5.11193 2.82884 4.26151 3.54518 3.54518C4.26151 2.82884 5.11193 2.26061 6.04787 1.87293C6.98381 1.48525 7.98695 1.28571 9 1.28571C10.0131 1.28571 11.0162 1.48525 11.9521 1.87293C12.8881 2.26061 13.7385 2.82884 14.4548 3.54518C15.1712 4.26151 15.7394 5.11193 16.1271 6.04787C16.5147 6.98381 16.7143 7.98695 16.7143 9C16.7143 11.046 15.9015 13.0081 14.4548 14.4548C13.0081 15.9015 11.046 16.7143 9 16.7143C6.95404 16.7143 4.99189 15.9015 3.54518 14.4548C2.09847 13.0081 1.28571 11.046 1.28571 9Z"
                            fill="#545454"
                          />
                        </svg>
                        Mandatory Packages you need to take for the duration for
                        which you have booked the property
                      </p>
                    </div> */}
                    <hr></hr>
                    {cart.length > 0 &&
                      cart.map(
                        (item, index) =>
                          item.status === "0" && (
                            <div
                              className="cleaning-plan-sec delux"
                              key={index}
                            >
                              <ul>
                                <li>
                                  <div className="plan-n-p">
                                    <p>
                                      {item.package_name} Cleaning Plan{" "}
                                      <span className="price">
                                        ${numberWithCommas(item.price)}
                                      </span>
                                    </p>
                                  </div>
                                </li>
                                <li>
                                  <div className="plan-frequency">
                                    <p>Frequency</p>
                                    <div className="add-item">
                                      <p>{item.qty}</p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )
                      )}

                    {/*{addCleaningPack === 'Basic' && (
                      <div className="cleaning-plan-sec delux">
                        <ul>
                          <li>
                            <div className="plan-n-p">
                              <p>Basic Cleaning Plan <span className="price">${numberWithCommas(basicPackPrice)}</span></p>
                            </div>
                          </li>
                          <li>
                            <div className="plan-frequency">
                              <p>Frequency</p>
                              <div className="add-item">
                                <p>1</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      )}
                      {addCleaningPack === 'Premium' && (
                      <div className="cleaning-plan-sec delux">
                        <ul>
                          <li>
                            <div className="plan-n-p">
                              <p>Premium Cleaning Plan <span className="price">${numberWithCommas(primiumPackPrice)}</span></p>
                            </div>
                          </li>
                          <li>
                            <div className="plan-frequency">
                              <p>Frequency</p>
                              <div className="add-item">
                                <p>1</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      )}*/}
                    <div className="sap_prem_btn cleaning">
                      <Button
                        // onClick={handleProceedButtonCleaning}
                        onClick={() => handleProceed()}
                        type="button"
                        disabled={loader}
                      >
                        Proceed
                        {loader && <Spinner />}
                      </Button>

                      {/* <Link to={`/cart`}> <button type="button">Proceed</button></Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default CleaningOtherPlans;
