import { Fragment, useEffect, useState } from "react";

import CartBack from "../../assets/images/cart-back.png";
import CloseCircle from "../../assets/images/close-circle.svg";
import Cartplan1 from "../../assets/images/cartplan1.svg";
import Del from "../../assets/images/del.svg";
import Edit from "../../assets/images/Edit.svg";
import CartCoupon from "../../assets/images/cart-coupon.svg";
import { useUserState } from "../../Contexts/UserContext";
import axios from "axios";
import { Button, Spinner } from "react-bootstrap";
import ModalComponent from "../common/ModalComponents";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../common/Loader";

import FurnitureBackImg from "../../assets/images/furniture-back.png";
import Cart1Img from "../../assets/images/cart1.png";
import Cart2Img from "../../assets/images/cart2.png";
import Cart3Img from "../../assets/images/cart3.png";

const AllDataInCart = () => {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const [cartData, setCartData] = useState([]);
  const [finalCartData, setFinalCartData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [totalAmountDays, setTotalAmountDays] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [cardData, setCardData] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [monthlypay, setMonthlypay] = useState(null);
  const fromDate = location?.state?.startDate;
  const toDate = location?.state?.endDate;
  const [proceedAmount, setProceesAmount] = useState();
  const [days, setDays] = useState(null);
  const [noOfMonths, setNoOfMonths] = useState(null);
  const [noOfRemaingDays, setNoOfRemainingDays] = useState();
  const [totalMonths, setTotalMonths] = useState();
  const [openCleaningDetails, setOpenCleaningDetails] = useState(false);
  const [minimizeView, setMinimizeView] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [cartId, setCartId] = useState("");
  const [houseId, setHouseId] = useState("");
  const [isFurnitureDataShow, setIsFurnitureDataShow] = useState(false);
const [isLoading , setLoading ] = useState(true)
const [isDisabledButton, setIsDisabledButton] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const cart = params.get("cart");
        const house = params.get("house");
        setCartId(cart);
        setHouseId(house);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.search]);

  if (!isLoggedIn) {
    navigate("/login");
  }
  function calculateDateDifference(startDate, endDate) {
    const differenceMs = endDate - startDate;
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24)) + 1;
    setDays(differenceDays);
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    let diffYear = endDate.getFullYear() - startDate.getFullYear();
    let diffMonth = endDate.getMonth() - startDate.getMonth();
    let months = diffYear * 12 + diffMonth;
    let totalDaysInLstMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate();
    if (months < 0 || (months === 0 && endDay < startDay)) {
      months += 12;
    }
    let remainingDays = endDay - startDay + 1;
    if (remainingDays === totalDaysInLstMonth) {
      months++, (remainingDays = 0);
    }
    if (remainingDays < 0) {
      months--;
      const tempDate = new Date(endDate);
      tempDate.setMonth(endDate.getMonth() - 1);
      remainingDays =
        new Date(tempDate.getFullYear(), tempDate.getMonth() + 1, 0).getDate() -
        startDay +
        endDay +
        1;
    }
    setTotalMonths(months + remainingDays / totalDaysInLstMonth);
    return {
      days: differenceDays,
      months: months,
      remainingDays: remainingDays,
    };
  }
  let counter = 1;

  const calculateInstallments = (
    startDate,
    endDate,
    totalAmount,
    securityDeposit
  ) => {
    const { months, remainingDays } = calculateDateDifference(
      startDate,
      endDate
    );
    let monthsRemaining = months;
    const installments = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const daysInMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      let daysToUse = monthsRemaining > 0 ? daysInMonth : remainingDays;
      monthsRemaining--;
      const installmentAmount = (daysToUse / daysInMonth) * totalAmount;
      const year = currentDate.getFullYear();
      const monthName = `${currentDate.toLocaleString("default", {
        month: "long",
      })}-${year}`;
      if (currentDate.getTime() === startDate.getTime()) {
        installments.push({
          month: `${monthName}( with security )`,
          amount: (installmentAmount + securityDeposit).toFixed(2),
        });
      } else {
        installments.push({
          month: monthName,
          amount: installmentAmount.toFixed(2),
        });
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return { installments, remainingDays, months };
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const headers = {
    Authorization: user?.token,
  };

  const FetchData = async () => {
    const baseCartUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/cart/get-by-house/${cartId}/${houseId}`;
    const baseCardUrl = `${
      import.meta.env.VITE_APP_BASE_URL
    }/api/payment-method/get-list`;
    try {
      const res = await axios.get(baseCartUrl, { headers });
      const cardRes = await axios.get(baseCardUrl, { headers });
      if (res.status === 200 && cardRes.status === 200) {
       
        let cartItems = res.data.data;
        setCardData(cardRes?.data?.data?.data);
        setCartData(cartItems);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateCartTotal = (data) => {
    const mainCart = data?.mainCart;

    if (!mainCart || !mainCart.furnitureCarts) {
      return null; // or handle the absence of mainCart as appropriate
    }

    const {
      furnitureCarts,
      cleaningServiceCarts,
      house_data: houseData,
    } = mainCart;

    const {
      name: houseName = "",
      monthly_price: housePrice = 0,
      security_prices: securityPrice = 0,
      houseImages = [],
    } = houseData || {};
    const owner_offered_price =
      mainCart.house_data?.priceNegotiateRequests[0]?.negotiationData[0]
        ?.owner_offered_price;
    const cust_offered_price =
      mainCart.house_data?.priceNegotiateRequests[0]?.negotiationData[0]
        ?.cust_offered_price;
    const cartId =
      furnitureCarts?.length > 0
        ? furnitureCarts[0].main_cart_id
        : cleaningServiceCarts[0].main_cart_id;
    const houseImage = houseImages.length > 0 ? houseImages[0].imageUrl : null;

    let furnitureTotalPrice = 0;
    let cleaningTotalPrice = 0;

    const furnitureNames = [];
    const cleaningNames = [];

    for (const item of furnitureCarts) {
      const price = Number(item.furniture_data.price);
      const quantity = item.quantity;
      furnitureTotalPrice += price * quantity;
      furnitureNames.push(item.furniture_category_data.name);
    }
    for (const service of cleaningServiceCarts) {
      const price = Number(service.cleaning_service_data.price);
      const frequency = service.frequency;
      cleaningTotalPrice += price * frequency;

      cleaningNames.push(service.cleaning_service_data.name);
    }
    //pending code -----
    const subTotal = (
      parseFloat(furnitureTotalPrice) +
      parseFloat(cleaningTotalPrice) / parseFloat(totalMonths) +
      parseFloat(housePrice)
    ).toFixed(2);
    const total = (parseFloat(subTotal) + parseFloat(securityPrice)).toFixed(2);
    return {
      cartId,
      furniture_name: furnitureNames,
      furniture_total_price: furnitureTotalPrice,
      furniture_image:
        furnitureCarts[0]?.furniture_data.furniture_images[0]?.imageUrl,
      cleaning_name: cleaningNames,
      cleaning_total_price: cleaningTotalPrice,
      house_name: houseName,
      house_price: owner_offered_price || cust_offered_price || housePrice,
      houseImage: houseImage,
      security_price: securityPrice,
      subTotal,
      total,
    };
  };
  // const calculateInstallments = (days, totalAmount, securityDeposit) => {
  //   const daysInMonth = 30;

  //   const installments = Array.from(
  //     { length: Math.ceil(days / daysInMonth) },
  //     (_, index) => {
  //       const remainingDays = Math.min(daysInMonth, days - index * daysInMonth);
  //       const installmentAmount = (remainingDays / daysInMonth) * totalAmount;

  //       if (index === 0) {
  //         // Add security deposit in the first month
  //         return {
  //           month: index + 1,
  //           amount: (installmentAmount + securityDeposit)?.toFixed(2),
  //         };
  //       } else {
  //         return { month: index + 1, amount: installmentAmount?.toFixed(2) };
  //       }
  //     }
  //   );

  //   const remainingDays = days % daysInMonth;

  //   return { installments, remainingDays };
  // };

  useEffect(() => {
    if (user?.token) {
      cartId && FetchData();
    }
  }, [user?.token, cartId, showModal]);
  useEffect(() => {
    try {
      if (Object.prototype.hasOwnProperty.call(cartData, "mainCart") ) {
        const calculatedCartTotal = calculateCartTotal(cartData);
        setFinalCartData(calculatedCartTotal);
        const calculatedTotalAmountDays =
          (calculatedCartTotal?.subTotal * days) / 30;

        // setTotalAmountDays(calculatedTotalAmountDays);
        // setTotalAmount(
        //   (
        //     calculatedTotalAmountDays + calculatedCartTotal?.security_price
        //   ).toFixed(2)
        // );

        const { installments, remainingDays, months } = calculateInstallments(
          new Date(fromDate),
          new Date(toDate),
          calculatedCartTotal?.subTotal,
          calculatedCartTotal?.security_price
        );
        setNoOfMonths(months);
        setNoOfRemainingDays(remainingDays);
        setMonthlypay(installments);
        setProceesAmount(
          installments
            .reduce((acc, { amount }) => acc + parseFloat(amount), 0)
            .toFixed(2)
        );
        setTotalAmountDays(installments[0].amount);
        setTotalAmount(
          installments
            .reduce((acc, { amount }) => acc + parseFloat(amount), 0)
            .toFixed(2)
        );
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }, [cartData, days]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
  useEffect(() => {
    if (isChecked) {
      setProceesAmount(parseFloat(finalCartData?.total).toFixed(2));
      setSubscriptionType("monthly");
    } else {
      setProceesAmount(parseFloat(totalAmount).toFixed(2));
      setSubscriptionType("one_time");
    }
  }, [isChecked, totalAmount, finalCartData]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(()=>{
    if(noOfMonths === 1 && noOfRemaingDays === 0){
      setIsChecked(false)
    }
  },[noOfMonths,noOfRemaingDays]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Fragment>
      <div className="banner-section customise-furniture-back">
        <img src={FurnitureBackImg} alt="assets" />
      </div>

      <div className="cart-sec">
        <div className="container-fluid my-container">
          {finalCartData ? (
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <h4>
                  Your selection ({" "}
                  {finalCartData.furniture_total_price === 0 ? "2" : "3"} items
                  )
                </h4>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <ul>
                          <li>
                            <img
                              src={
                                finalCartData.houseImage
                                  ? import.meta.env.VITE_APP_BASE_URL +
                                    finalCartData?.houseImage
                                  : Cartplan1
                              }
                              alt=""
                            />
                            {finalCartData.house_name}
                          </li>
                          <li>
                            ${finalCartData.house_price}/per month
                            {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                          </li>
                        </ul>
                      </button>
                    </h2>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <ul>
                          <li>
                            <img src={Cartplan1} alt="assets" /> Cleaning Plan
                            Details
                          </li>
                          <li>
                            ${finalCartData.cleaning_total_price}
                            {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                          </li>
                        </ul>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Cleaning Plan Name</th>
                                <th scope="col">Cleaning Frequency</th>
                                <th scope="col">Price Per Plan</th>
                                <th scope="col">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cartData?.mainCart.cleaningServiceCarts?.map(
                                (cartdata, key) => (
                                  <tr key={key}>
                                    <td>
                                      <img src={Cartplan1} alt="assets" />{" "}
                                      {cartdata.cleaning_service_data.name}
                                    </td>
                                    <td>{cartdata.frequency}</td>
                                    <td>
                                      ${cartdata.cleaning_service_data.price}
                                      /per plan
                                    </td>
                                    <td>
                                      $
                                      {cartdata.frequency *
                                        cartdata.cleaning_service_data.price}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {finalCartData.furniture_total_price > 0 && (
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <ul>
                            <li>
                              <img src={Cart3Img} alt="assets" />{" "}
                              {finalCartData?.furniture_name[0] ||
                                "Furniture Plan Details"}
                            </li>
                            <li>
                              ${finalCartData?.furniture_total_price}/per month
                              {/* <span>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.8484 9.14001L18.1984 19.21C18.0884 20.78 17.9984 22 15.2084 22H8.78844C5.99844 22 5.90844 20.78 5.79844 19.21L5.14844 9.14001"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.3281 16.5H13.6581"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.5 12.5H14.5"
                                  stroke="#23262F"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span> */}
                            </li>
                          </ul>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Furniture Package Name</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Price Monthly</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartData?.mainCart.furnitureCarts?.map(
                                  (cartdata, key) => (
                                    <tr key={key}>
                                      <td>
                                        <img
                                          src={
                                            import.meta.env.VITE_APP_BASE_URL +
                                            cartdata.furniture_data
                                              .furniture_images[0].imageUrl
                                          }
                                          alt="assets"
                                        />{" "}
                                        {cartdata.furniture_data.name}
                                      </td>
                                      <td>{cartdata.quantity}</td>
                                      <td>
                                        $ {cartdata.furniture_data.price}/per
                                        month
                                      </td>
                                      <td>
                                        $
                                        {cartdata.quantity *
                                          cartdata.furniture_data.price}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <h4>Cart Total</h4>
                <div className="sub-total-new">
                  <ul>
                    <li>
                      Sub total ( monthly) :{" "}
                      <span>${finalCartData?.subTotal}</span>
                    </li>
                    <li>
                      Security Deposit (Non-refundable) :{" "}
                      <span>${finalCartData?.security_price}</span>
                    </li>
                    <li>
                      Total Amount : ( {noOfMonths}{" "}
                      {noOfMonths === 1 ? "Month" : "Months"}{" "}
                      {noOfRemaingDays > 0 &&
                        `and ${noOfRemaingDays} Day${
                          noOfRemaingDays !== 1 ? "s" : ""
                        }`}{" "}
                      ) <span>${totalAmount}</span>
                    </li>
                  </ul>

                  {isChecked && (
                    <>
                      <div className="description-heading">
                        Monthly Rent Amount
                      </div>

                      <ul className="month-rent">
                        {monthlypay.map((element, i) => (
                          <li key={i}>
                            {element.month}
                            <span>${element.amount}</span>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}

                  <div className="description-heading">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                          !isChecked ||
                          (noOfMonths === 1 && noOfRemaingDays === 0)
                        } // Checked by default and checked when condition is met
                        disabled={noOfMonths === 1 && noOfRemaingDays === 0}
                        onChange={handleCheckboxChange}
                        id="flexCheckDefault3"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault3"
                      >
                        Pay Full Amount
                      </label>
                    </div>
                  </div>
                  <button
                    className="proceed-btn  d-flex align-items-center justify-content-center"
                    onClick={handleOpenModal}
                    disabled={isDisabledButton}
                  >
                    {isDisabledButton ? (
                      <Loader />
                    ) : (
                      `Proceed to Checkout: ${parseFloat(proceedAmount).toFixed(
                        2
                      )}`
                    )}
                  </button>

                  <ModalComponent
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleCloseModal={handleCloseModal}
                    cardData={cardData}
                    FetchCardData={FetchData}
                    totalAmount={parseFloat(proceedAmount).toFixed(2)}
                    cartId={finalCartData?.cartId}
                    subscription_type={subscriptionType}
                    housePrice={finalCartData?.house_price}
                    setIsDisabledButton={setIsDisabledButton}
                  ></ModalComponent>
                </div>
              </div>
            </div>
          ) : (
            // <div className="d-flex justify-content-center">
            //   <Spinner />
            // </div>

            <div class="eye-loader-frame m-5 p-5">
              <div class="eye-loader-spinner"></div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AllDataInCart;
