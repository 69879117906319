import React, { Fragment } from "react";

import FurnithistParkImg from "../../../assets/images/furnithistpark.png";
import Account2Img from "../../../assets/images/account2.png";
import CallImg from "../../../assets/images/call.png";
import MessageImg from "../../../assets/images/message.png";
import LocationPinImg from "../../../assets/images/location_pin.png";
import FurnithistPlanImg from "../../../assets/images/furnithistplan.png";
import FurnitFridaImg from "../../../assets/images/furnitfrida.png";
import FurnitFrida2Img from "../../../assets/images/furnitfrida2.png";

const MyHistory = () => {
  return (
    <Fragment>
      <div className="main-box">
        <div className="content-header">
          <h3>My History</h3>
        </div>
        <div className="furnit_hist">
          <div className="furnit_hist_left">
            <div className="furnit_book_profile_cont">
              <div className="profile-sec">
                <img src={FurnithistParkImg} />
              </div>
              <ul className="furnit_book_inner_cont">
                <li>
                  <img src={Account2Img} alt="account2" />
                  <p>Peter Parker</p>
                </li>
                <li>
                  <img src={CallImg} alt="call" />
                  <p>+1 5623 895 XXX</p>
                </li>
                <li>
                  <img src={MessageImg} alt="message" />
                  <p>peter123@gmail.com</p>
                </li>
                <li>
                  <img src={LocationPinImg} alt="message" />
                  <p>42 Broadway, New York, NY 10004, USA</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="furnit_hist_right">
            <div className="furnit_hist_plan_cont">
              <img src={FurnithistPlanImg} alt="furnit" />
              <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
                <h4>Delux Furniture Plan</h4>
                <h4>Starting Date : 1 May 2023</h4>
                <h2>View Delux Furniture Items</h2>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="furnit_hist">
          <div className="furnit_hist_left">
            <div className="furnit_book_profile_cont">
              <div className="profile-sec">
                <img src={FurnitFridaImg} />
              </div>
              <ul className="furnit_book_inner_cont">
                <li>
                  <img src={Account2Img} alt="account2" />
                  <p>Frida</p>
                </li>
                <li>
                  <img src={CallImg} alt="call" />
                  <p>+1 5623 895 XXX</p>
                </li>
                <li>
                  <img src={MessageImg} alt="message" />
                  <p>peter123@gmail.com</p>
                </li>
                <li>
                  <img src={LocationPinImg} alt="message" />
                  <p>42 Broadway, New York, NY 10004, USA</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="furnit_hist_right">
            <div className="furnit_hist_plan_cont">
              <img src={FurnithistPlanImg} alt="furnit" />
              <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
                <h4>Delux Furniture Plan</h4>
                <h4>Starting Date : 1 May 2023</h4>
                <h2>View Delux Furniture Items</h2>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="furnit_hist">
          <div className="furnit_hist_left">
            <div className="furnit_book_profile_cont">
              <div className="profile-sec">
                <img src={FurnitFrida2Img} />
              </div>
              <ul className="furnit_book_inner_cont">
                <li>
                  <img src={Account2Img} alt="account2" />
                  <p>Frida</p>
                </li>
                <li>
                  <img src={CallImg} alt="call" />
                  <p>+1 5623 895 XXX</p>
                </li>
                <li>
                  <img src={MessageImg} alt="message" />
                  <p>peter123@gmail.com</p>
                </li>
                <li>
                  <img src={LocationPinImg} alt="message" />
                  <p>42 Broadway, New York, NY 10004, USA</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="furnit_hist_right">
            <div className="furnit_hist_plan_cont">
              <img src={FurnithistPlanImg} alt="furnit" />
              <div className="furnit_delux_cont notific_modal_cont_inner furnit_head">
                <h4>Delux Furniture Plan</h4>
                <h4>Starting Date : 1 May 2023</h4>
                <h2>View Delux Furniture Items</h2>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </div>
    </Fragment>
  );
};

export default MyHistory;
