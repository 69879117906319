import { Fragment, useEffect } from "react";

import HeroBannerSec from "../../home/heroBannerSec/HeroBannerSec";

import AppPromo from "../../home/apppromo/AppPromo";
import PropertySearch from "./PropertySearch";

const PropertyListing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  },[]);
  return (
    <Fragment>
      {/* <HeroBannerSec /> */}
      <PropertySearch />
      <AppPromo />
    </Fragment>
  );
};

export default PropertyListing;
