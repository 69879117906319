import { Fragment } from "react";

import { Modal } from "react-bootstrap";

const PrivacyModal = ({privacyModal, setPrivacyModal}) => {
  return (
    <Fragment>
      <Modal
        show={privacyModal}
        onHide={() => setPrivacyModal(false)}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tristique lorem ac nunc varius fringilla. Morbi vitae ligula ut elit
            volutpat vulputate ut in felis. Praesent luctus, eros ac tincidunt
            euismod, mi massa volutpat erat, eget suscipit ante lectus a eros.
            Duis ante nisl, blandit nec lorem et, fermentum gravida erat.
            Quisque sollicitudin magna id pulvinar lobortis. Integer scelerisque
            augue in tincidunt vehicula. Duis suscipit aliquam magna, non
            dapibus turpis interdum quis. Nam luctus nisi vel erat convallis, eu
            sagittis ex ultrices. Sed sed odio euismod, fermentum tortor a,
            malesuada massa. Sed ac diam ut ante vestibulum elementum. Nunc id
            eros ut odio blandit commodo non non mi. Proin nec nunc nec sapien
            tristique feugiat. Aenean lobortis nibh dolor, eget iaculis libero
            lacinia eu. Praesent facilisis id mauris hendrerit eleifend. Etiam
            sed elit eget massa rhoncus venenatis.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tristique lorem ac nunc varius fringilla. Morbi vitae ligula ut elit
            volutpat vulputate ut in felis. Praesent luctus, eros ac tincidunt
            euismod, mi massa volutpat erat, eget suscipit ante lectus a eros.
            Duis ante nisl, blandit nec lorem et, fermentum gravida erat.
            Quisque sollicitudin magna id pulvinar lobortis. Integer scelerisque
            augue in tincidunt vehicula. Duis suscipit aliquam magna, non
            dapibus turpis interdum quis. Nam luctus nisi vel erat convallis, eu
            sagittis ex ultrices. Sed sed odio euismod, fermentum tortor a,
            malesuada massa. Sed ac diam ut ante vestibulum elementum. Nunc id
            eros ut odio blandit commodo non non mi. Proin nec nunc nec sapien
            tristique feugiat. Aenean lobortis nibh dolor, eget iaculis libero
            lacinia eu. Praesent facilisis id mauris hendrerit eleifend. Etiam
            sed elit eget massa rhoncus venenatis.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tristique lorem ac nunc varius fringilla. Morbi vitae ligula ut elit
            volutpat vulputate ut in felis. Praesent luctus, eros ac tincidunt
            euismod, mi massa volutpat erat, eget suscipit ante lectus a eros.
            Duis ante nisl, blandit nec lorem et, fermentum gravida erat.
            Quisque sollicitudin magna id pulvinar lobortis. Integer scelerisque
            augue in tincidunt vehicula. Duis suscipit aliquam magna, non
            dapibus turpis interdum quis. Nam luctus nisi vel erat convallis, eu
            sagittis ex ultrices. Sed sed odio euismod, fermentum tortor a,
            malesuada massa. Sed ac diam ut ante vestibulum elementum. Nunc id
            eros ut odio blandit commodo non non mi. Proin nec nunc nec sapien
            tristique feugiat. Aenean lobortis nibh dolor, eget iaculis libero
            lacinia eu. Praesent facilisis id mauris hendrerit eleifend. Etiam
            sed elit eget massa rhoncus venenatis.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            tristique lorem ac nunc varius fringilla. Morbi vitae ligula ut elit
            volutpat vulputate ut in felis. Praesent luctus, eros ac tincidunt
            euismod, mi massa volutpat erat, eget suscipit ante lectus a eros.
            Duis ante nisl, blandit nec lorem et, fermentum gravida erat.
            Quisque sollicitudin magna id pulvinar lobortis. Integer scelerisque
            augue in tincidunt vehicula. Duis suscipit aliquam magna, non
            dapibus turpis interdum quis. Nam luctus nisi vel erat convallis, eu
            sagittis ex ultrices. Sed sed odio euismod, fermentum tortor a,
            malesuada massa. Sed ac diam ut ante vestibulum elementum. Nunc id
            eros ut odio blandit commodo non non mi. Proin nec nunc nec sapien
            tristique feugiat. Aenean lobortis nibh dolor, eget iaculis libero
            lacinia eu. Praesent facilisis id mauris hendrerit eleifend. Etiam
            sed elit eget massa rhoncus venenatis.
          </p>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default PrivacyModal;
