import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import plusSvg from "../assets/images/plus.svg";
import furnishedSvg from "../assets/images/furnished.svg";
import buildingSvg from "../assets/images/Building.svg";
import waterGlassSvg from "../assets/images/Water_Glass.svg";
import carSvg from "../assets/images/car.svg";
import lockAccessSvg from "../assets/images/lock_access.svg";
import directionToolSvg from "../assets/images/direction_tool.svg";
import RulerSvg from "../assets/images/ruler.svg";
import BathTubSvg from "../assets/images/Bathtub.svg";
import BedImage from "../assets/images/Bed.svg";
import axios from "axios";
import { useUserState, useUserDispatch } from "../Contexts/UserContext";
import { HouseContext } from "../Contexts/HouseContext";
import { Button, Modal, Spinner } from "react-bootstrap";
import { Tooltip } from "antd";

// import "./assets/css/custom.css";
// import "./assets/css/styles.css";
function OwnerHouse({ toggleComponentHouse }) {
  const { user, isLoggedIn } = useUserState();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const { house, setHouse } = useContext(HouseContext);
  const [isModal, setIsModal] = useState(false);
  const [myHouseList, setMyHouseList] = useState([]);
  useEffect(() => {
    if (user?.token) {
      getHouseList();
    }
  }, [user?.token]);
  const headers = {
    Authorization: user?.token,
  };
  const getHouseList = async () => {
    const res = await axios.get(
      `${import.meta.env.VITE_APP_BASE_URL}/api/house/get-by-user`,
      {
        headers: headers,
      }
    );
    setIsLoader(false);
    if (res) {
      setMyHouseList(res.data.data);
    } else {
      alert("error");
    }
  };

  const editHouse = (value) => {
    toggleComponentHouse();
    setHouse({ ...value });
  };

  const removeModal = (houseId) => {
    setIsModal(true);
    setHouseId(houseId);
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [houseId, setHouseId] = useState(false);
  const handleRemoveSubmit = async () => {
    setIsButtonLoading(true);
    const headersFormData = {
      "Content-Type": "application/json",
      Authorization: user?.token,
    };
    if (user?.token) {
      try {
        let res = await axios.delete(
          `${import.meta.env.VITE_APP_BASE_URL}/api/house/delete/${houseId}`,
          { headers: headersFormData }
        );
        setIsButtonLoading(false);
        setIsModal(false);
        getHouseList();
      } catch (err) {
        setIsButtonLoading(false);
        return console.log(err);
      }
    }
  };

  
  return (
    <main>
      <style>
        {`
          .palm_step {
            flex-wrap: wrap;
          }
          .palm_step li {
            margin: 0 13px 13px 0;
          }
        `}
      </style>
      <div className="container-fluid px-4">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="main-box">
              <div className="content-header">
                <h3>My Houses</h3>
                <a
                  onClick={() => {
                    toggleComponentHouse();
                  }}
                  style={{ color: "white" }}
                >
                  <img src={plusSvg} alt="plus" /> Add New House
                </a>
              </div>
              {myHouseList.length > 0 &&
                myHouseList.map((value, i) => (
                  <div
                    key={i + "_houses"}
                    className="row"
                    // onClick={() => navigate(`/property_detail/${value?.id}`)}
                  >
                    <div className="col-12">
                      <div className="hist_main">
                        <div
                          className="hist_img"
                          onClick={() => {
                            editHouse(value);
                          }}
                        >
                          {value.houseImages.length > 0 && (
                            <img
                              style={{ width: "250px", height: "212px" }}
                              src={
                                import.meta.env.VITE_APP_BASE_URL +
                                value.houseImages[0].imageUrl
                              }
                              alt="histhomeimg"
                            />
                          )}
                          <div className="brook_house" style={{justifyContent: "flex-start"}}>
                            {value.houseImages.length > 1 &&
                              value.houseImages.map(
                                (imgValue, j) =>
                                  j > 0 && (
                                    <div key={j} className="house_img">
                                      <img
                                        style={{
                                          width: "75px",
                                          height: "70px",
                                          paddingRight: "5px",
                                        }}
                                        src={
                                          import.meta.env.VITE_APP_BASE_URL +
                                          imgValue.imageUrl
                                        }
                                        alt="hsofa"
                                      />
                                    </div>
                                  )
                              )}
                          </div>
                        </div>
                        <div className="hist_cont">
                          <div
                            className="house_drop"
                            onClick={() => {
                              editHouse(value);
                            }}
                          >
                            <h4>{value.name}</h4>
                            <div className="house_dinner">
                              <h2
                                style={{ color: "#ff5d1f", fontWeight: "bold" }}
                              >
                                {value?.booking_status}
                              </h2>
                              {/* <select
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Booked</option>
                                <option value="1">Not Booked</option>
                              </select> */}
                            </div>
                          </div>
                          <p
                             onClick={() => {
                              editHouse(value);
                            }}
                          >
                            {/* {value.country},  */}
                            {value?.full_address?.split(";")[0]},{" "}
                            {value?.full_address?.split(";")[1]},{" "}
                            {value?.full_address?.split(";")[2]}
                            <br />
                            {value.state}, {value.zip_code}
                          </p>
                          <h4
                             onClick={() => {
                              editHouse(value);
                            }}
                          >
                            ${value.monthly_price + value?.total_utilities_price}/
                            <span className="per_month">per month</span>
                          </h4>
                          <h4
                             onClick={() => {
                              editHouse(value);
                            }}
                          >
                            Overview
                          </h4>
                          <ul
                            className="palm_step"
                            onClick={() => {
                              editHouse(value);
                            }}
                          >
                            {value.house_facility && (
                              <li>
                                <img src={furnishedSvg} alt="furnished" />
                                <h4>
                                  {value?.house_facility === "Customize"
                                    ? "Unfurnished"
                                    : value?.house_facility}
                                </h4>
                              </li>
                            )}
                            {value?.floor && (
                              <li>
                                <img src={buildingSvg} alt="furnished" />
                                <h4>{value?.floor}</h4>
                              </li>
                            )}
                            {value.area && (
                              <li>
                                <img src={RulerSvg} alt="furnished" />
                                <h4>{value.area} SQ FT</h4>
                              </li>
                            )}
                            {value.bedroom_no && (
                              <li>
                                <img src={BedImage} alt="furnished" />
                                <h4>{value.bedroom_no} Bedrooms</h4>
                              </li>
                            )}
                            {value.bathroom_no && (
                              <li>
                                <img src={BathTubSvg} alt="furnished" />
                                <h4>{value.bathroom_no} Bathrooms</h4>
                              </li>
                            )}
                          </ul>
                          <ul
                            className="palm_step palm_steps"
                            onClick={() => {
                              editHouse(value);
                            }}
                          >
                            <li>
                              <img src={carSvg} alt="furnished" />
                              <h4>{value.house_type}</h4>
                            </li>
                          </ul>
                          {value?.booking_status === "Booked" ? (
                            <Tooltip title="You can't edit or remove this property as it is already booked">
                              <button
                                className="house_det clickable"
                                onClick={() => {
                                  editHouse(value);
                                }}
                                disabled={value?.booking_status === "Booked"}
                                style={{
                                  backgroundColor:
                                    value?.booking_status === "Booked"
                                      ? "gray"
                                      : "",
                                }}
                              >
                                Edit Details{" "}
                                <i className="fa fa-solid fa-pen-to-square"></i>
                              </button>
                              <button
                                className="house_rem clickable"
                                onClick={() => {
                                  removeModal(value?.id);
                                }}
                                disabled={value?.booking_status === "Booked"}
                                style={{
                                  backgroundColor:
                                    value?.booking_status === "Booked"
                                      ? "gray"
                                      : "",
                                }}
                              >
                                Remove <i className="fa fa-solid fa-trash"></i>
                              </button>
                            </Tooltip>
                          ) : (
                            <>
                              <button
                                className="house_det clickable"
                                onClick={() => {
                                  editHouse(value);
                                }}
                                style={{
                                  backgroundColor:
                                    value?.booking_status === "Booked"
                                      ? "gray"
                                      : "",
                                }}
                              >
                                Edit Details{" "}
                                <i className="fa fa-solid fa-pen-to-square"></i>
                              </button>
                              <button
                                className="house_rem clickable"
                                onClick={() => {
                                  removeModal(value?.id);
                                }}
                                style={{
                                  backgroundColor:
                                    value?.booking_status === "Booked"
                                      ? "gray"
                                      : "",
                                }}
                              >
                                Remove <i className="fa fa-solid fa-trash"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <hr className="m-0" />
                    </div>
                  </div>
                ))}
              {isLoader && (
                <div class="eye-loader-frame m-5 p-5">
                <div class="eye-loader-spinner"></div>
              </div>
              )}

              {myHouseList.length == 0 && !isLoader && (
                <div className="loading-data">
                  There is no house added by you.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Email Otp Modal */}
      <Modal
        show={isModal}
        onHide={() => setIsModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        id="emailOtpModal"
      >
        <Modal.Header closeButton>Confirming Deletion</Modal.Header>

        <Modal.Body>
          <div className="confirmation-box">
            <p>Do you really want to remove this property?</p>
            <div className="dual-btn">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveSubmit();
                }}
                disabled={isButtonLoading}
              >
                Yes
                {isButtonLoading && <Spinner></Spinner>}
              </Button>
              <Button onClick={() => setIsModal(false)}>No</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
}

export default OwnerHouse;