import React, { Fragment, useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Input } from "antd";
import axios from "axios";

import { useUserState, UserContext } from "../../../Contexts/UserContext";
import { toast } from "react-toastify";

const AddCleaningService = ({ furnitureData }) => {
  const { user } = useUserState();
  const navigate = useNavigate();
  const { cleanEditData } = useContext(UserContext);
  const [isCleaningData, setIsCleaningData] = useState(false);
  const [inputData, setInputData] = useState({
    basicPrice: "",
    basicDescription: "",
    premiumPrice: "",
    premiumDescription: "",
    deluxPrice: "",
    deluxDescription: "",
  });
  const [inputError, setInputError] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (furnitureData && furnitureData.length > 0) {
      setIsCleaningData(true);
      let basicPrice,
        basicDescription,
        premiumPrice,
        premiumDescription,
        deluxPrice,
        deluxDescription;
      furnitureData.map((cleaning) => {
        console.log(cleaning.cleaning_service_category_id);
        if (cleaning.cleaning_service_category_id === "3") {
          (basicPrice = cleaning?.price),
            (basicDescription = cleaning?.description);
        }
        if (cleaning.cleaning_service_category_id === "2") {
          (premiumPrice = cleaning.price),
            (premiumDescription = cleaning?.description);
        }
        if (cleaning.cleaning_service_category_id === "1") {
          (deluxPrice = cleaning?.price),
            (deluxDescription = cleaning?.description);
        }
      });
      setInputData({
        basicPrice: basicPrice,
        basicDescription: basicDescription,
        premiumPrice: premiumPrice,
        premiumDescription: premiumDescription,
        deluxPrice: deluxPrice,
        deluxDescription: deluxDescription,
      });
    }
  }, [furnitureData]);

  console.log("input", inputData);

  const handleButtonClick = () => {
    setShowSuccess(true);
  };

  const handleSubmit = async () => {
    const {
      basicPrice,
      basicDescription,
      premiumPrice,
      premiumDescription,
      deluxPrice,
      deluxDescription,
    } = inputData;

    if (
      !basicPrice ||
      !basicDescription ||
      !premiumPrice ||
      !premiumDescription ||
      !deluxPrice ||
      !deluxDescription
    ) {
      setInputError(true);
    } else {
      setInputError(false);

      const headerJson = {
        "Content-Type": "application/json",
        Authorization: user?.token,
      };

      const CleaningServiceData = [
        {
          name: "Basic Cleaning",
          price: basicPrice,
          description: basicDescription,
          cleaning_service_category_id: "3",
        },
        {
          name: "Premium Cleaning",
          price: premiumPrice,
          description: premiumDescription,
          cleaning_service_category_id: "2",
        },
        {
          name: "Delux Cleaning",
          price: deluxPrice,
          description: deluxDescription,
          cleaning_service_category_id: "1",
        },
      ];

      try {
        setBtnLoader(true);
        const res = await axios.post(
          `${import.meta.env.VITE_APP_BASE_URL}/api/cleaning-service/create`,
          { CleaningServiceData },
          { headers: headerJson }
        );
        if (res) {
          setBtnLoader(false);
          handleButtonClick();
          //toast.success("Add cleaning service successfully!! ")
          //navigate("/user_dash/cleaning_company/cleaning_store");

          // setInputData({basicPrice: "",
          // basicDescription: "",
          // premiumPrice: "",
          // premiumDescription: "",
          // deluxPrice: "",
          // deluxDescription: "",

          // })
          setIsCleaningData(true);
        }
      } catch (err) {
        console.log(err);
        setBtnLoader(false);
      }
    }
  };
  const handleUpdate = () => {
    setIsCleaningData(false);
    setShowSuccess(false)

  };

  return (
    <Fragment>
      <div>
        {showSuccess && (
          <div className="success-clean">
            Add cleaning service successfully!!
          </div>
        )}
      </div>

      <div class="plan-sec add-clean-pg">
        <div class="plan-name-sec">
          <h4>Basic plan</h4>
          <div class="plan_field">
            <label htmlFor="basicPrice" class="form-label">
              Price <span className="text-danger">*</span>
            </label>
            <Input
              id="basicPrice"
              type="text"
              addonBefore="$"
              disabled={isCleaningData}
              addonAfter="/month"
              value={inputData?.basicPrice}
              onKeyPress={(e) => {
                if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  basicPrice: e.target.value,
                })
              }
            />
            {inputError && !inputData?.basicPrice && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter basic monthly price!
              </div>
            )}
          </div>
          <div class="plan_field">
            <label htmlFor="basicDescription" class="form-label">
              What we do in basic plan <span className="text-danger">*</span>
            </label>
            <textarea
              class="form-control"
              id="basicDescription"
              rows="3"
              disabled={isCleaningData}
              placeholder="Add Description"
              value={inputData?.basicDescription}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  basicDescription: e.target.value,
                })
              }
            ></textarea>

            {inputError && !inputData?.basicDescription && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter basic description!
              </div>
            )}
          </div>
        </div>
        <div class="plan-name-sec">
          <h4>Premium plan</h4>
          <div class="plan_field">
            <label htmlFor="premiumPrice" class="form-label">
              Price <span className="text-danger">*</span>
            </label>
            <Input
              type="text"
              id="premiumPrice"
              addonBefore="$"
              disabled={isCleaningData}
              addonAfter="/month"
              value={inputData?.premiumPrice}
              onKeyPress={(e) => {
                if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  premiumPrice: e.target.value,
                })
              }
            />

            {inputError && !inputData?.premiumPrice && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter basic premium price!
              </div>
            )}
          </div>
          <div class="plan_field">
            <label htmlFor="premiumDescription" class="form-label">
              What we do in premium plan <span className="text-danger">*</span>
            </label>
            <textarea
              class="form-control"
              id="premiumDescription"
              rows="3"
              disabled={isCleaningData}
              placeholder="Add Description"
              value={inputData?.premiumDescription}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  premiumDescription: e.target.value,
                })
              }
            ></textarea>

            {inputError && !inputData?.premiumDescription && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter premium description!
              </div>
            )}
          </div>
        </div>
        <div class="plan-name-sec">
          <h4>Delux plan</h4>
          <div class="plan_field">
            <label htmlFor="deluxPrice" class="form-label">
              Price <span className="text-danger">*</span>
            </label>
            <Input
              type="text"
              id="deluxPrice"
              addonBefore="$"
              disabled={isCleaningData}
              addonAfter="/month"
              value={inputData?.deluxPrice}
              onKeyPress={(e) => {
                if (!/(?:^| )\d+(\.\d+)?(?=$| )/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  deluxPrice: e.target.value,
                })
              }
            />
            {inputError && !inputData?.deluxPrice && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter delux monthly price!
              </div>
            )}
          </div>
          <div class="plan_field">
            <label htmlFor="deluxDescription" class="form-label">
              What we do in delux plan <span className="text-danger">*</span>
            </label>
            <textarea
              class="form-control"
              id="deluxDescription"
              rows="3"
              disabled={isCleaningData}
              placeholder="Add Description"
              value={inputData?.deluxDescription}
              onChange={(e) =>
                setInputData({
                  ...inputData,
                  deluxDescription: e.target.value,
                })
              }
            ></textarea>
            {inputError && !inputData?.deluxDescription && (
              <div className="sap-val-error text-danger mb-0 mt-1">
                Please enter delux description!
              </div>
            )}
          </div>
        </div>

        
        <button
          type="button"
          className="druyp-btn plan-action"
          disabled={btnLoader}
          onClick={() => (isCleaningData ? handleUpdate() : handleSubmit())}
        >
          {isCleaningData ? "Update" : btnLoader ? <Spinner /> : "Save Details"}
        </button>
      </div>
    </Fragment>
  );
};

export default AddCleaningService;
