import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  OAuthProvider,
} from "firebase/auth";
import { auth } from "./firebase";

const UserAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState();
  const [isPopupSuccess, setIsPopupSuccess] = useState(false);

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .then((res) => {
        setIsPopupSuccess(true);
      })
      .catch((err) => {
        setIsPopupSuccess(false);
        console.log("Googlepopuperr", err);
      });
  }
  function facebookSignIn() {
    const facebookAuthProvider = new FacebookAuthProvider();
    return signInWithPopup(auth, facebookAuthProvider)
      .then((res) => {
        setIsPopupSuccess(true);
      })
      .catch((err) => {
        console.log("Googlepopuperr", err);
        setIsPopupSuccess(false);
      });
  }

  function appleSignIn() {
    const appleAuthProvider = new OAuthProvider("apple.com");
    return signInWithPopup(auth, appleAuthProvider)
      .then((res) => {
        setIsPopupSuccess(true);
      })
      .catch((err) => {
        setIsPopupSuccess(false);
        console.log("Googlepopuperr", err);
      });
  }
  function verifyCaptcha(value) {
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {}
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, value, recaptchaVerifier);
  }
  useEffect(() => {
    // setCookie('name', user||cookies.name)
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  return (
    <UserAuthContext.Provider
      value={{
        user,
        signUp,
        logIn,
        googleSignIn,
        facebookSignIn,
        verifyCaptcha,
        appleSignIn,
        isPopupSuccess,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}
export function useUserAuth() {
  return useContext(UserAuthContext);
}
