import { Fragment, useEffect } from "react";
import TestimonialSec from "../property/testimonialSec/TestimonialSec";
import ChooseFurniturePackageSec from "./chooseFurniturePackageSec/ChooseFurniturePackage";
import "./Furniture.css";
import furnitureBack from "../../assets/images/furniture-back.png";

const FurniturePackage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  },[]);
  return (
    <Fragment>
      <div className="banner-section customise-furniture-back">
        <img src={furnitureBack} alt="..." />
      </div>
      <ChooseFurniturePackageSec />
      <TestimonialSec />
    </Fragment>
  );
};

export default FurniturePackage;
