import { Fragment } from "react";

import Slider from "react-slick";

import Slide1Img from "../../../assets/images/slide1.png";
import Slide2Img from "../../../assets/images/slide2.png";
import Slide3Img from "../../../assets/images/slide3.png";
import LocationSvg from "../../../assets/images/location.svg";
import CalenderSvg from "../../../assets/images/calendar.svg";

import "./HeroBannerStyle.css";

const HeroBannerSec = () => {
  const settings = {
    dots: true, // Enable indicators
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023, // Small screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991, // Small screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767, // Small screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 499, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <Fragment>
      <div className="banner-section">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {/* <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div> */}
          <div className="carousel-inner">
            <Slider {...settings}>
              <div className="carousel-item active">
                <img src={Slide1Img} alt="..." />
              </div>
              <div className="carousel-item">
                <img src={Slide2Img} alt="..." />
              </div>
              <div className="carousel-item">
                <img src={Slide3Img} alt="..." />
              </div>
            </Slider>
          </div>
        </div>
        <div className="search-home my-container">
          <div className="search-header">
            <h2>
              Find the <span>right home</span> and <span>customize</span> to fit
              your needs
            </h2>
            <p>
              We Help you find the best places and offer near you.Bring to the
              table win-win survival strategies to ensure proactive domination
              going forward.
            </p>
          </div>
          <div className="search-form">
            <div className="input-sec">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Location<span className="star_symb">*</span>
              </label>
              <div className="search_image_icon">
                <img src={LocationSvg} />
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter the location"
                />
              </div>
            </div>
            <div className="input-sec">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Date<span className="star_symb">*</span>
              </label>
              <div className="search_image_icon">
                <img src={CalenderSvg} />
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Select dates"
                />
              </div>
            </div>
            <div className="input-sec">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Choose category<span className="star_symb">*</span>
              </label>
              <div className="choose-sec">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    All
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault1"
                  >
                    Fully Furnished
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault2"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault2"
                  >
                    Customise
                  </label>
                </div>
              </div>
            </div>
            <div className="input-sec button-sec">
              <a href="#">Search</a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HeroBannerSec;
