import "./App.css";
import RouteTable from "./Routes/RouteTable";
import { UserAuthContextProvider } from "./components/UserAuthContext";
import { useUserState } from "./Contexts/UserContext";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
function App() {
const { user, isLoggedIn } = useUserState();
  return (
    <div>
      <UserAuthContextProvider>
        
        <RouteTable />
      </UserAuthContextProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
