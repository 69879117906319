//import this from firebase SDK
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDARn6xAeS-YvPlEsCzZvw53b5mC9CvdtE",
  authDomain: "druyp-414204.firebaseapp.com",
  projectId: "druyp-414204",
  storageBucket: "druyp-414204.appspot.com",
  messagingSenderId: "732213545842",
  appId: "1:732213545842:web:dd94536ef3becac5efebeb",
  measurementId: "G-F34GFPF2SE"
};
const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)
export default app
