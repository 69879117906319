import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import openEye from "../assets/icons/open-eye.svg";
import closeEye from "../assets/icons/closed-eye.svg";
import mail from "../assets/icons/mail.svg";
import axios from "axios";
const Change_password = () => {
  const [details, setDetails] = useState({
    pwd: "",
    cpwd: "",
  });

  const [isPasswordEyeOpen, setIsPasswordEyeOpen] = useState(false);
  const [isCNFPasswordEyeOpen, setIsCNFPasswordEyeOpen] = useState(false);
  const [confirmPwd, setConfirmPwd] = useState(true);
  const [errMessage, setErrorMsg] = useState("");
  const location = useLocation();
  const { fUser } = location.state;
  const navigate = useNavigate();

  function handleChange(evt) {
    const value = evt.target.value;
    setDetails({
      ...details,
      [evt.target.name]: value,
    });
  }

  const headers = {
    "Content-Type": "application/json",
  };
  const handleResetPwd = async () => {
    if (details.pwd) {
      setErrorMsg("");
      if (details.pwd !== details.cpwd) {
        setConfirmPwd(false);
        setErrorMsg("Password not matched !!");
      } else {
        let data = {
          email: fUser,
          password: details.pwd,
        };
        try {
          let res = await axios.post(
            `${import.meta.env.VITE_APP_BASE_URL}/api/user/updatePwd`,
            data,
            { headers: headers }
          );
          if (res?.data?.message === "Password reset successfully") {
            navigate("/login");
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      setErrorMsg("Please Enter Password !!");
    }
  };

  return (
    <div>
      <div>
        <section className="druyp_login_sec">
          <div className="container-fluid">
            <div className="row align-items-center min-vh-100">
              <div className="col-lg-6">
                <div className="druyp_login_inner">
                  <div className="druyp_logo ">Druyp </div>
                  <h4>Change Password</h4>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3 druyp_mail">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Enter your new password
                        </label>
                        <input
                          type={isPasswordEyeOpen ? "text" : "password"}
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your password"
                          name="pwd"
                          value={details.pwd}
                          onChange={handleChange}
                        />
                        <div className="mail_icon">
                          <img src={mail} alt="mail image" />
                        </div>
                        {isPasswordEyeOpen ? (
                          <div
                            className="eye_icon"
                            onClick={() =>
                              setIsPasswordEyeOpen(!isPasswordEyeOpen)
                            }
                          >
                            <img src={openEye} alt="open Eye" />
                          </div>
                        ) : (
                          <div
                            className="eye_icon"
                            onClick={() =>
                              setIsPasswordEyeOpen(!isPasswordEyeOpen)
                            }
                          >
                            <img src={closeEye} alt="close Eye" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3 druyp_mail">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Confirm your password
                        </label>
                        <input
                          type={isCNFPasswordEyeOpen ? "text" : "password"}
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Confirm your password"
                          name="cpwd"
                          value={details.cpwd}
                          onChange={handleChange}
                        />
                        <div className="mail_icon">
                          <img src={mail} alt="mail image" />
                        </div>
                        {isCNFPasswordEyeOpen ? (
                          <div
                            className="eye_icon"
                            name="cpwd"
                            onClick={() =>
                              setIsCNFPasswordEyeOpen(!isCNFPasswordEyeOpen)
                            }
                          >
                            <img src={openEye} alt="open Eye" />
                          </div>
                        ) : (
                          <div
                            className="eye_icon"
                            onClick={() =>
                              setIsCNFPasswordEyeOpen(!isCNFPasswordEyeOpen)
                            }
                          >
                            <img src={closeEye} alt="close Eye" />
                          </div>
                        )}
                      </div>
                      {errMessage && <p className="error">{errMessage}</p>}
                      {!confirmPwd && <div className="error"></div>}
                    </div>
                    <div className="col-12">
                      <div className="druyp_login_btn">
                        <button onClick={handleResetPwd} type="submit">
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Change_password;
