import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      return false;
    } else {
      return true;
    }
  };

  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
