import visaImage from "../assets/images/visaimg.png";
import paypalImage from "../assets/images/paypal.png";
import mastercardImage from "../assets/images/mastercard.png";
import payapproveImage from "../assets/images/payapprove.png";
import payrightImage from "../assets/images/payright.png";
import plus from "../assets/images/plus.svg";
function UserBilling() {
  return (
    <>
      <div className="main-box billing_det_sec">
        <div className="content-header">
          <h3>My Billing Details</h3>
          <a href="#">
            <img src={plus} alt="plus" /> Add New Card
          </a>
        </div>
        <div className="bill_cont_main">
          <div className="bill_cont_left">
            <h4>My Cards</h4>
            <div className="bill_card_main">
              <div className="bill_card_det">
                <ul className="visa_num">
                  <li>
                    <div className="visa">
                      <img src={visaImage} alt="" />
                    </div>
                  </li>
                  <li>**** **** **** 5967</li>
                </ul>
                <a href="#">Remove</a>
              </div>
              <div className="bill_card_det">
                <ul className="visa_num">
                  <li>
                    <div className="visa">
                      <img src={paypalImage} alt="" />
                    </div>
                  </li>
                  <li>wilson.casper@bernice.info</li>
                </ul>
                <a href="#">Remove</a>
              </div>
              <div className="bill_card_det">
                <ul className="visa_num">
                  <li>
                    <div className="visa">
                      <img src={mastercardImage} alt="" />
                    </div>
                  </li>
                  <li>**** **** **** 3461</li>
                </ul>
                <a href="#">Remove</a>
              </div>
            </div>
          </div>
          <div className="bill_cont_right">
            <h4>My Transactions History</h4>
            <div className="transt_hist_main">
              <div className="receive_pay">
                <div className="transt_hist_inner">
                  <div className="payapprove_img">
                    <img src={payapproveImage} alt="Approve" />
                  </div>
                  <div className="pay_head">
                    <h4>Payment Received</h4>
                    <p> 03rd May 2023</p>
                  </div>
                </div>
                <div className="doler_num">
                  <h2>$2330</h2>
                  <img src={payrightImage} alt="" />
                </div>
              </div>
              <div className="receive_pay">
                <div className="transt_hist_inner">
                  <div className="payapprove_img">
                    <img src={payapproveImage} alt="Approve" />
                  </div>
                  <div className="pay_head">
                    <h4>Payment Received</h4>
                    <p> 03rd May 2023</p>
                  </div>
                </div>
                <div className="doler_num">
                  <h2>$2330</h2>
                  <img src={payrightImage} alt="" />
                </div>
              </div>
              <div className="receive_pay">
                <div className="transt_hist_inner">
                  <div className="payapprove_img">
                    <img src={payapproveImage} alt="Approve" />
                  </div>
                  <div className="pay_head">
                    <h4>Payment Received</h4>
                    <p> 03rd May 2023</p>
                  </div>
                </div>
                <div className="doler_num">
                  <h2>$2330</h2>
                  <img src={payrightImage} alt="" />
                </div>
              </div>
              <div className="receive_pay">
                <div className="transt_hist_inner">
                  <div className="payapprove_img">
                    <img src={payapproveImage} alt="Approve" />
                  </div>
                  <div className="pay_head">
                    <h4>Payment Received</h4>
                    <p> 03rd May 2023</p>
                  </div>
                </div>
                <div className="doler_num">
                  <h2>$2330</h2>
                  <img src={payrightImage} alt="" />
                </div>
              </div>
              <div className="receive_pay">
                <div className="transt_hist_inner">
                  <div className="payapprove_img">
                    <img src={payapproveImage} alt="Approve" />
                  </div>
                  <div className="pay_head">
                    <h4>Payment Received</h4>
                    <p> 03rd May 2023</p>
                  </div>
                </div>
                <div className="doler_num">
                  <h2>$2330</h2>
                  <img src={payrightImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bank_acc_det">
        <h2>My Bank Account Details</h2>
        <ul className="bank_acc_item">
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Account Name</h4>
              <p>Account Name Hapag-Lloyed (New Zealand) Ltd.</p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Bank Name</h4>
              <p>Citibank </p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Bank Address</h4>
              <p>23 customs street east, Auckland, New Zealand, 1010</p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Account Number</h4>
              <p>00322565XXXXXX</p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Swift Address</h4>
              <p>CitiNZSJ</p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Currency</h4>
              <p>USD</p>
            </div>
          </li>
          <li>
            <img src={payapproveImage} alt="paya" />
            <div className="aac_inner_det">
              <h4>Swift Code Intermediary</h4>
              <p>CITIUS33</p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default UserBilling;
