import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./propertySearch.css";
import axios from "axios";
import slide3 from "@images/slide3.png";
import location from "@images/location.svg";
import search from "@images/search.svg";
import bed from "@images/Bed.svg";
import bathroom from "@images/Bathtub.svg";
import ruler from "@images/ruler.svg";
import property from "@images/property12.png";

import heart from "@images/heart.svg";

import redheart from "@images/heart-red.svg";
import { useUserState, useUserDispatch } from "../../../Contexts/UserContext";

function PropertySearch() {
  const { user, isLoggedIn } = useUserState();
  const headers = {
    Authorization: user?.token,
  };
  const [queryParams, setQueryParams] = useState({
    state: "",
    type: "",
    name: "",
    amenities: "",
    bedroom_no: "",
    bathroom_no: "",
    full_address: "",
    minarea: "",
    maxarea: "",
    minprice: "",
    maxprice: "",
    page_no: "",
    house_type: "",
    house_facility: "",
    sort: "",
    searchKey: "",
  });
  const [houseData, setHouseData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [order, setOrder] = useState("");
  const [sortVal, setSortVal] = useState("");
  const [locSearch, setLocSearch] = useState(null);
  const [imageSrc, setImageSrc] = useState(heart);

  const {
    state: paramState,
    type,
    name,
    amenities,
    bedroom_no,
    bathroom_no,
    full_address,
    minarea,
    maxarea,
    minprice,
    maxprice,
    page_no,
    house_type,
    house_facility,
    sort,
    searchKey,
  } = useParams();

  useEffect(() => {
    // Set query parameters from URL parameters
    searchData();
  }, [
    queryParams.paramState,
    queryParams.type,
    queryParams.name,
    queryParams.amenities,
    queryParams.bedroom_no,
    queryParams.bathroom_no,
    queryParams.full_address,
    queryParams.minarea,
    queryParams.maxarea,
    queryParams.minprice,
    queryParams.maxprice,
    queryParams.page_no,
    queryParams.house_type,
    queryParams.house_facility,
    queryParams.sort,
    queryParams.searchKey,
  ]);
  const sortKey = (sortVal, order) => {
    const key = `${sortVal}${order}`;
    setQueryParams((prev) => {
      return { ...prev, sort: key };
    });
  };

  const addFav = async (parameter) => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/house/favorite-house/${parameter}`,
        {
          headers: headers,
        }
      );
      if (response) {
        if (response.data.message == true) {
          setImageSrc(redheart);
        } else {
          setImageSrc(heart);
        }
        searchData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const searchData = async () => {
    const apiUrl = isLoggedIn
      ? `${
          import.meta.env.VITE_APP_BASE_URL
        }/api/search/propertySearchWithUserId`
      : `${import.meta.env.VITE_APP_BASE_URL}/api/search/propertySearch`;

    //alert(apiUrl);
    try {
      let data = await axios.get(apiUrl, {
        headers: headers,
        params: queryParams,
      });

      setHouseData([...data.data.results]);
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    searchData();
    setQueryParams({
      state: paramState || "",
      type: type || "",
      name: name || "",
      amenities: amenities || "",
      bedroom_no: bedroom_no || "",
      bathroom_no: bathroom_no || "",
      full_address: full_address || "",
      minarea: minarea || "",
      maxarea: maxarea || "",
      minprice: minprice || "",
      maxprice: maxprice || "",
      page_no: page_no || "",
      house_type: house_type || "",
      house_facility: house_facility || "",
      sort: sort || "",
      searchKey: searchKey || "",
    });
  }, [user?.token]);
  const handleChange = (key, value) => {
    // Update the state with the new value
    setQueryParams((prevParams) => ({ ...prevParams, [key]: value }));
  };

  return (
    <div>
      <div className="banner-section">
        <img src={slide3} alt="..." />
        <div className="search-home my-container">
          <div className="search-header">
            <h2>
              Find Your <span>Dream Home</span>
            </h2>
            <p>We’ve more than 745,000 apartments, place & plot.</p>
          </div>
          <div className="search-form property-search">
            <div className="input-sec">
              <label className="form-label">
                Enter an address or Zip code<span className="star_symb">*</span>
              </label>
              <img src={location} />
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter an address, neighbourhood, or Zip code"
                onChange={(e) => {
                  setQueryParams((prev) => {
                    return { ...prev, searchKey: e.target.value };
                  });
                }}
              />
            </div>
            {/* <div
              className="input-sec button-sec"
              onClick={() => {
                setQueryParams((prev) => {
                  return { ...prev, searchKey: locSearch };
                });
              }}
            >
              <a>Search</a>
            </div> */}
          </div>
        </div>
      </div>

      <div className="all-property-sec">
        <div className="container-fluid my-container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <div className="sidebar-listing">
                <div className="specialities-checkbox">
                  {/* <div className="searcher-sec">
                    <h4>Find your home</h4>
                    <div className="form-group">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="What are you looking for?"
                        value={queryParams.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      <img src={search} />
                    </div>
                  </div> */}
                </div>
                <div className="specialities-checkbox">
                  <div className="listing-check">
                    <h4>Property Category</h4>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck01"
                        // checked={
                        //   queryParams.house_facility === "fully furnished"
                        // }
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_facility", "fully furnished");
                          } else {
                            handleChange("house_facility", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">
                        Fully Furnished
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck02"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_facility", "customize");
                          } else {
                            handleChange("house_facility", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">
                        Customizable
                      </label>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Property Type</h4>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck05"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_type", "house");
                          } else {
                            handleChange("house_type", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">House</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck06"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_type", "apartments");
                          } else {
                            handleChange("house_type", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">Apartments</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck07"
                        checked={queryParams.house_type === "office"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_type", "office");
                          } else {
                            handleChange("house_type", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">Office</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck08"
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleChange("house_type", "villa");
                          } else {
                            handleChange("house_type", "");
                          }
                        }}
                      />
                      <label className="custom-control-label">Villa</label>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Price Range</h4>
                    {/* <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      id="customRange2"
                    /> */}
                    <div className="custom-price">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="$0"
                        value={queryParams.minprice}
                        onChange={(e) =>
                          handleChange("minprice", e.target.value)
                        }
                      />
                      <i className="fa fa-minus"></i>
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="$50000"
                        value={queryParams.maxprice}
                        onChange={(e) =>
                          handleChange("maxprice", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Bedroom</h4>
                    <div className="date-slot activity-sec">
                      <ul>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            onChange={(e) => handleChange("bedroom_no", "")}
                            name="bedroom_no"
                          />
                          <a className="">Any</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            onChange={(e) => handleChange("bedroom_no", 1)}
                          />
                          <a className="">1</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            onChange={(e) => handleChange("bedroom_no", 2)}
                          />
                          <a className="">2</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            onChange={(e) => handleChange("bedroom_no", 3)}
                          />
                          <a className="">3</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bedroom_no"
                            onChange={(e) => handleChange("bedroom_no", 4)}
                          />
                          <a className="">4+</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Bathroom</h4>
                    <div className="date-slot activity-sec">
                      <ul>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={(e) => handleChange("bathroom_no", "")}
                          />
                          <a className="">Any</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={(e) => handleChange("bathroom_no", 1)}
                          />
                          <a className="">1</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={(e) => handleChange("bathroom_no", 2)}
                          />
                          <a className="">2</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={(e) => handleChange("bathroom_no", 3)}
                          />
                          <a className="">3</a>
                        </li>
                        <li className="list-group-item">
                          <input
                            type="radio"
                            id="male"
                            name="bathroom_no"
                            onChange={(e) => handleChange("bathroom_no", 4)}
                          />
                          <a className="">4+</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="listing-check">
                    <h4>Square Feet</h4>
                    {/* <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      id="customRange2"
                      onChange={(e) =>
                        handleChange("bathroom_no", e.target.value)
                      }
                    /> */}
                    <div className="custom-price">
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Min."
                        value={queryParams.minarea}
                        onChange={(e) =>
                          handleChange("minarea", e.target.value)
                        }
                      />
                      <i className="fa fa-minus"></i>
                      <input
                        type="Name"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Max"
                        value={queryParams.maxarea}
                        onChange={(e) =>
                          handleChange("maxarea", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
              <div className="filter-sec">
                <p>Showing 1–10 of {houseData.length} results</p>
                <div className="sort-sec">
                  <span>Order :</span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setOrder(e.target.value);
                      sortKey(sortVal, e.target.value);
                    }}
                  >
                    <option value="" selected>
                      -
                    </option>
                    <option value="Desc">High to Low</option>
                    <option value="Asc">Low to High</option>
                  </select>
                </div>
                <div className="sort-sec">
                  <span>Sort by :</span>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSortVal(e.target.value);
                      sortKey(e.target.value, order);
                    }}
                  >
                    <option value="" selected>
                      -
                    </option>
                    <option value="price">Price</option>
                    <option value="area">Area</option>
                    <option value="bed">Bedroom</option>
                    <option value="bath">Bathroom</option>
                  </select>
                </div>
              </div>
              <div className="row">
                {houseData.map((house, key) => {
                  return (
                    <div
                      key={key}
                      className="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4"
                    >
                      <div className="property-listing">
                        <div className="property-sec">
                          <div className="property-inner">
                            <span className="type-pro">
                              {house.house_facility}
                            </span>
                            <Link to={`/property_detail/${house.id}`}>
                              <img
                                src={
                                  import.meta.env.VITE_APP_BASE_URL +
                                  house.images[house.images.length - 1]
                                }
                              />
                            </Link>
                            <div className="property-content">
                              <div className="property-price">
                                <p className="pricing">
                                  ${house.monthly_price}/<sub>month</sub>
                                </p>

                                {isLoggedIn && (
                                  <a onClick={() => addFav(house.id)}>
                                    {/* Use a ternary operator to conditionally render the heart icon */}
                                    {house.isFavorite ? (
                                      <img
                                        className="prop-Heart"
                                        style={{ height: "30px" }}
                                        src={redheart}
                                      />
                                    ) : (
                                      <img
                                        className="prop-Heart"
                                        src={
                                          house.isFavorite ? redheart : heart
                                        }
                                      />
                                    )}
                                  </a>
                                )}
                              </div>
                              <div className="property-discription">
                                <a>
                                  <h4>{house.name}</h4>
                                  <p>{`${house.full_address} ${house.state} ${house.country} `}</p>
                                </a>
                              </div>
                              <div className="property-overview">
                                <ul>
                                  <li>
                                    <img src={bed} /> {house.bedroom_no} Bedroom
                                  </li>
                                  <li>
                                    <img src={bathroom} /> {house.bathroom_no}{" "}
                                    Bathroom
                                  </li>
                                  <li>
                                    <img src={ruler} /> {house.area} SQ FT
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertySearch;
