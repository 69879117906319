import React, { useEffect, useState, useContext } from "react";
import {
  CardNumberElement,
  CardElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./index.css";
import axios from "axios";

import { UserContext } from "../../../Contexts/UserContext";

import VisaSvg from "../../../assets/cardImages/Visa.png";
import AmericanExp from "../../../assets/cardImages/American.png";
import MasterCardSvg from "../../../assets/cardImages/Mastercard.png";
import UpaySvg from "../../../assets/cardImages/UnionPay.png";
import CvvImg from "../../../assets/cardImages/cvv.png";

export default function MyCard({
  token,
  setTitle,
  getPaymentDetails,
  setIsCard,
}) {
  const { setAddCardModal } = useContext(UserContext);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [metadata, setMetadata] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const headerJson = {
    "Content-Type": "application/json",
    Authorization: token,
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setErrorMessage('');
  //   setProcessing(true);

  //   if (!stripe || !elements) {
  //     // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
  //     setErrorMessage('Stripe.js has not loaded yet.');
  //     setProcessing(false);
  //     return;
  //   }

  //   try {
  //     const result = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardNumberElement),
  //       billing_details: {
  //         name: name,
  //       },
  //     });

  //     if (result.error) {
  //       setErrorMessage(result.error.message);
  //       setProcessing(false);
  //       return;
  //     }

  //     const response = await axios.post(
  //       `${import.meta.env.VITE_APP_BASE_URL}/api/payment-method/attach`,
  //       { paymentMethod: result.paymentMethod },
  //       { headers: headerJson }
  //     );

  //     setName('');
  //     setErrorMessage('');
  //     setProcessing(false);
  //     setTitle("Card Payment Added");
  //         // setMetadata(resp.paymentIntent);

  //         elements.getElement(CardNumberElement).clear();
  //         elements.getElement(CardExpiryElement).clear();
  //         elements.getElement(CardCvcElement).clear();

  //         setIsCard(true);
  //         let element = document.getElementById("close-btn");
  //         if (element) {
  //           element.click();
  //         }
  //         getPaymentDetails();

  //     // Handle success, e.g., display a success message or redirect the user
  //     console.log('Payment method successfully added:', response.data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setErrorMessage(error.message);
  //     setProcessing(false);
  //   }
  // };

  // const renderForm = () => {
  //   const options = {
  //     style: {
  //       base: {
  //         color: "#32325d",
  //         fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //         fontSmoothing: "antialiased",
  //         fontSize: "16px",
  //         "::placeholder": {
  //           color: "#aab7c4",
  //         },
  //       },
  //       invalid: {
  //         color: "#fa755a",
  //         iconColor: "#fa755a",
  //       },
  //     },
  //   };

  //   return (
  //     <form onSubmit={handleSubmit}>
  //       <div className="sr-combo-inputs">
  //         <div className="sr-combo-inputs-row">
  //           <input
  //             type="text"
  //             id="name"
  //             name="name"
  //             placeholder="Name"
  //             autoComplete="cardholder"
  //             className="sr-input"
  //             value={name}
  //             onChange={(e) => setName(e.target.value)}
  //           />
  //         </div>

  //         <div className="sr-combo-inputs-row">
  //           <CardElement
  //             className="sr-input sr-card-element"
  //             options={optiaddressons}
  //             // onChange={(e) => {
  //             //   setErrorMsg(e.error.message);
  //             //   // console.log("e card change", e, e.error.message);
  //             // }}
  //             // onReady={(e) => {
  //             //   console.log("e on Ready", e);
  //             // }}
  //           />
  //         </div>
  //       </div>
  //       {errorMsg && <div className="message sr-field-error">{errorMsg}</div>}

  //       <button className="btn" disabled={processing}>
  //         {processing ? "Processing…" : "Add Card"}
  //       </button>
  //     </form>
  //   );
  // };

  const [cardBrand, setCardBrand] = useState(null);

  const cardBrands = [
    { brand: "visa", icon: VisaSvg },
    { brand: "mastercard", icon: MasterCardSvg },
    { brand: "amex", icon: AmericanExp },
    { brand: "discover", icon: UpaySvg },
  ];

  const [displayedIcons, setDisplayedIcons] = useState(
    cardBrands?.map((brand) => brand?.icon)
  );

  useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement) {
        cardElement.on("change", (event) => {
          const brand = event?.brand;
          setCardBrand(brand);
          if (brand) {
            const selectedBrand = cardBrands?.find((b) => b.brand === brand);
            if (selectedBrand) {
              setDisplayedIcons([selectedBrand?.icon]);
            }
          } else {
            setDisplayedIcons(cardBrands?.map((brand) => brand?.icon));
          }
        });
      }
    }
  }, [elements, cardBrands]);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js has not loaded yet. Make sure to disable
  //     // form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   const { paymentMethod, error } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: {
  //       number: elements.getElement(CardNumberElement),
  //       exp_month: elements.getElement(CardExpiryElement).cardExpiry.month,
  //       exp_year: elements.getElement(CardExpiryElement).cardExpiry.year,
  //       cvc: elements.getElement(CardCvcElement),
  //     },
  //     billing_details: {
  //       // Optionally collect billing details (name, address, etc.)
  //     },
  //   });

  //   if (error) {
  //     // Handle errors gracefully
  //     console.error("Saving card error:", error);
  //   } else {
  //     // Send the payment method ID to your server for storage
  //     fetch("/save-card", {
  //       method: "POST",
  //       body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("Card saved:", data);
  //         // Handle successful card saving (e.g., display confirmation message)
  //       })
  //       .catch((error) => {
  //         console.error("Saving card error:", error);
  //         // Handle saving errors
  //       });
  //   }
  // };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    
    setErrorMsg("");
    if (errorMsg) {
      console.log("err------->", errorMsg);
      //return "";
    }

    console.log("elements.getElement(CardElement)", ev);
    if (!name) {
      setErrorMsg("Please fill in all required fields.");
      return;
    }

    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
   
    const resp = await stripe.createPaymentMethod({
      
      type: "card",
      billing_details: {
        name: name,
        address:{
          line1: address, 
           city: city,
           state: state,
           postal_code: zipcode,
           country: 'US',
        }
      },
      card: elements.getElement(CardNumberElement),
    });
    if (resp.error) {
      setErrorMsg(resp.error.message);
      return;
    }

    console.log("addresss,",address)

    setProcessing(true);
    //console.log("resp-->", resp);
    try {
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/payment-method/attach`,
        { paymentMethod: resp.paymentMethod },
        {
          headers: headerJson,
        }
      );
      getPaymentDetails();
      setName("");
      setAddress("");
      setError(null);
      setProcessing(false);
      setAddCardModal(false);
      setTitle("Card Payment Added");
      setMetadata(resp.paymentIntent);

      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();

      setIsCard(true);
      setDisplayedIcons(cardBrands?.map((brand) => brand?.icon))
      let element = document.getElementById("close-btn");
      if (element) {
        element.click();
      }
      
    } catch (err) {
      console.log("err-", err.response.data.message);
      setErrorMsg(`${err.response.data.message} !!`);
      setProcessing(false);
      return;
    }
  };

  return (
    <>
      <div className="checkout-form">
        <div className="sr-payment-form">
          <div className="sr-form-row" />
          {/* {renderForm()} */}
          {/* <form className="payment-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <label className="form-label">Your name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                autoComplete="cardholder"
                className="card-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-row">
              <label className="form-label">Card Number</label>
              <CardNumberElement className="card-input" />
            </div>
            <div className="form-row">
              <label className="form-label">Expiration Date</label>
              <CardExpiryElement className="card-input" />
            </div>
            <div className="form-row">
              <label className="form-label">CVC</label>
              <CardCvcElement className="card-input" />
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <button className="submit-button" type="submit" disabled={!stripe || processing}>
            {processing ? 'Processing...' : 'Add Card'}
            </button>
          </form> */}
          <form onSubmit={handleSubmit} className="card-store row">
            <div class="col-md-12 form-group" id="fname">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* <div className="sap-val-error text-danger">Enter Full Name</div> */}
            <div class="col-md-12 form-group" id="cnum">
              <CardNumberElement className="form-control" />
              <div className="payment-icon">
                {displayedIcons.map((icon, index) => (
                  <div key={index}>
                    <img src={icon} alt="icon" />
                  </div>
                ))}
              </div>
            </div>
            <div class="col-md-6 form-group" id="expirydate">
              <CardExpiryElement className="form-control" />
            </div>
            <div class="col-md-6 form-group" id="cvv">
              <CardCvcElement className="form-control" />{" "}
              <img src={CvvImg} alt="icon" />
            </div>
            <div class="col-md-12 form-group" id="address">
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div class="col-md-4 form-group" id="address">
              <input
                type="text"
                className="form-control"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div class="col-md-4 form-group" id="address">
              <input
                type="text"
                className="form-control"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div class="col-md-4 form-group" id="address">
              <input
                type="text"
                className="form-control"
                placeholder="Zipcode"
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
              />
              
            </div>
            <div class="col-md-12 form-group cs-btn">
              <button type="submit" className="btn btn-lg btn-primary" disabled={processing}>{processing ? "Processing…" : "Save Card"}</button>
            </div>
            {errorMsg && <div className="sap-val-error text-danger text-center mb-0 mt-1">{errorMsg}</div>}
            {/* <div className="sap-val-error text-danger text-center mb-0 mt-1">
              Enter Full Name
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
}
