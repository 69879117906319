import { Fragment, useEffect, useLayoutEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useUserState } from "../../Contexts/UserContext";

import HeroBannerSec from "./heroBannerSec/HeroBannerSec";
import TopPropertiesSec from "./topPropertiesSec/TopPropertiesSec";
import WhyChoose from "./whychoosesec/WhyChoose";
import PropertyGallery from "./propertygallery/PropertyGallery";
import TestimonialsSec from "./testimonialsSec/TestimonialsSec";
import AppPromo from "./apppromo/AppPromo";

const Home = () => {
  const { user } = useUserState();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if(user?.currentRole === "cleaning_company" || user?.currentRole === "furniture_company" || user?.currentRole === "home_owner"){
      navigate(`/user_dash/${user?.currentRole}`);
    } else {
      navigate("/");
    }
  },[user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  },[]);

  return (
    <Fragment>
      <HeroBannerSec />
      <div className="top-property-sec">

      <TopPropertiesSec />
      </div>
      <WhyChoose />
      <PropertyGallery />
      <TestimonialsSec />
      <AppPromo />
    </Fragment>
  );
};

export default Home;
