import { useState } from "react";
import { useNavigate } from "react-router-dom";
import mail from "../assets/icons/mail.svg";
import axios from "axios";

const Forgot_password = () => {
  // const { user, setUser } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [checkEmail,setCheckEmail]=useState(false)
  const navigate = useNavigate();
  const headers = {
    "Content-Type": "application/json",
  };
  const setOtp = async () => {
    if (email) {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if(!emailRegex.test(email)){
         setCheckEmail(true)
         return;
      }
      setCheckEmail(false)
      let data = {
        email: email,
      };
      try{
      let res = await axios.post(
        `${import.meta.env.VITE_APP_BASE_URL}/api/user/resetPwd`,
        data,
        { headers: headers }
      );
      if (res) {
        navigate("/otp", { state: { fUser: email } });
      }
    }catch(err){
      console.log(err)
    }
    }
    else {
      setCheckEmail(true)
    }

  };
  return (
    <div>
      <section className="druyp_login_sec">
        <div className="container-fluid">
          <div className="row align-items-center min-vh-100">
            <div className="col-lg-6">
              <div className="druyp_login_inner">
                <div className="druyp_logo ">Druyp </div>
                <h4>Forget Password</h4>
                <div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3 druyp_mail">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Please Enter Email associated with your account
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Enter your email address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <div className="mail_icon">
                          <img src={mail} alt="mail image" />
                        </div>
                      </div>
                      {checkEmail &&
                        <div className="error">
                          Please enter valid email id .
                        </div>
                      }
                    </div>
                    <div className="col-12">
                      <div className="druyp_login_btn">
                        <button onClick={setOtp} type="submit">
                          Reset Password
                        </button>
                      </div>

                      <div
                        className="create_new"
                        onClick={() => {
                          navigate(`/`);
                        }}
                      >
                        Back to Login
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Forgot_password;
