import { Fragment, useEffect } from "react";

import Slider from "react-slick";

import TestimonialsSec from "../components/home/testimonialsSec/TestimonialsSec";
import TopPropertiesSec from "../components/home/topPropertiesSec/TopPropertiesSec";
import WhyChoose from "../components/home/whychoosesec/WhyChoose";

import CartBackImg from "../assets/images/cart-back.png";
import Property1Img from "../assets/images/property1.png";
import Property2Img from "../assets/images/property2.png";
import Property3Img from "../assets/images/property3.png";

const AboutUs = () => {
  const settings = {
    dots: true, // Enable indicators
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1023, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 499, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <Fragment>
      <div className="banner-section cart-banner contact">
        <img src={CartBackImg} alt="..." />
        <h2>About us</h2>
      </div>

      <div className="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-md-7">
              <div className="about-content-sec">
                <h2>About Druyp</h2>
                <p>
                  <span>HELLO, CUSTOMERS!</span>Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Pellentesque euismod orci neque,
                  nec fringilla ligula semper at. Pellentesque euismod viverra
                  est, sit amet placerat massa interdum vel. Cras risus lacus,
                  suscipit at turpis sit amet, porttitor viverra lacus. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque euismod orci neque, nec fringilla ligula semper
                  at. Pellentesque euismod viverra est, sit amet placerat massa
                  interdum vel. Cras risus lacus, suscipit at turpis sit amet,
                  porttitor viverra lacus.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-5 col-md-5">
              {/* <div className="owl-carousel owl-theme" id="travel-slider"> */}
              <Slider {...settings}>
                <div className="property-sec">
                  <div className="property-inner">
                    <span className="type-pro">Customise</span>
                    <img src={Property1Img} />
                  </div>
                </div>
                <div className="property-sec">
                  <div className="property-inner">
                    <span className="type-pro">Fully Furnished</span>
                    <img src={Property2Img} />
                  </div>
                </div>
                <div className="property-sec">
                  <div className="property-inner">
                    <span className="type-pro">Fully Furnished</span>
                    <img src={Property3Img} />
                  </div>
                </div>
              </Slider>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <WhyChoose />
      <div className="top-property-sec">
        <TopPropertiesSec />
      </div>
      <div className="about-inner">
        <TestimonialsSec />
      </div>
    </Fragment>
  );
};

export default AboutUs;
